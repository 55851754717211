/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'

import PageContainer from 'components/page'
import AddPhilHealthForm from 'components/forms/addPhilHealthForm/AddPhilHealthForm'

export default function AddPhilHealthPage() {
    return (
        <PageContainer>
            <AddPhilHealthForm />
        </PageContainer>
    )
}
