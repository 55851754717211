/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { Loader } from 'vspry-style-components'
import { PublicPageContainer } from 'components/page'

export default function FetchingPage() {
    return (
        <PublicPageContainer>
            <Loader inverted />
        </PublicPageContainer>
    )
}
