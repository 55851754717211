/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'

import { useAuth } from 'context/authContext'
import { useRouting } from 'context/routingContext'
import { useFunctionState } from 'vspry-hooks'
import { ErrorSwal, SuccessSwal } from 'vspry-style-components'
import { VspryForm } from 'vspry-components'

import { useTheme } from 'context/brandContext'
import { checkValues, LeavingSwal } from '../util'
import ReimbursementDetailsFormPage from './pages/ReimbursementDetailsFormPage'
import UploadFormPage from './pages/UploadFormPage'

export default function ReimbursementApplicationForm() {
    const { refreshUser, user } = useAuth()
    const { navigateHome, navigateBack } = useRouting()
    const theme = useTheme()
    const [state, setState] = useFunctionState({
        fullName: user?.person?.fullName ?? '',
        cardNumber: user?.holdings?.mediLinkPH?.cardNumber ?? '',
        provider: '',
        claimType: '',
        chargeSlips: [],
        BIRReceipts: [],
        providerCertificates: [],
        statements: [],
        termsAndConditions: false,
    })

    // eslint-disable-next-line no-promise-executor-return
    const createReimbursementRequest = async () => new Promise((r) => setTimeout(() => r(true), 1000))
    const handleSubmit = async () => {
        const res = await createReimbursementRequest()
        if (!res)
            return ErrorSwal.fire({
                title: 'Error!',
                text: 'Seems something went wrong. Did you enter the correct details?',
                iconColor: theme.error,
                confirmButtonColor: theme.brand1,
            })
        SuccessSwal.fire({ title: 'Success', text: 'Reimbursement request successfully lodged.' })
        refreshUser()
        return navigateHome()
    }

    const onBack = () => {
        if (checkValues(state)) return LeavingSwal(theme, navigateBack)
        return navigateBack()
    }

    return (
        <VspryForm onSubmit={handleSubmit} onBack={onBack}>
            <VspryForm.Page>
                <ReimbursementDetailsFormPage state={state} setState={setState} />
            </VspryForm.Page>
            <VspryForm.Page>
                <UploadFormPage state={state} setState={setState} />
            </VspryForm.Page>
        </VspryForm>
    )
}
