/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'

import { useRouting } from 'context/routingContext'
import { useAuth } from 'context/authContext'

import cards from 'style/cards'

import PageContainer from 'components/page'
import InsuranceCard from 'components/cards/InsuranceCard'
import GovernmentCard from 'components/cards/GovernmentCard'
import AddCardButton from 'components/styled/buttons/AddCardButton'

export default function Home() {
    const { user, features } = useAuth()
    const { navigateTo } = useRouting()

    const renderDashboard = ({ mediLinkPH, philHealth }) => (
        <>
            {mediLinkPH && <InsuranceCard card={mediLinkPH} onClick={() => navigateTo('/card')} />}
            {features.PhilHealth && philHealth && <GovernmentCard card={philHealth} onClick={() => navigateTo('/philhealth')} />}
            {features.PhilHealth && !philHealth && (
                <AddCardButton id='add-philhealth-card' brand={cards.philhealth} onClick={() => navigateTo('/addphilhealth')}>
                    Add PhilHealth Card
                </AddCardButton>
            )}
        </>
    )

    return <PageContainer $wide>{user?.holdings && renderDashboard(user.holdings)}</PageContainer>
}
