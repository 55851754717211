/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
/* eslint-disable class-methods-use-this */
/* eslint-disable dot-notation */

import { auth } from 'api/firebase'
import {
    applyActionCode,
    browserSessionPersistence,
    confirmPasswordReset,
    onAuthStateChanged,
    PhoneAuthProvider,
    RecaptchaVerifier,
    sendEmailVerification,
    sendPasswordResetEmail,
    setPersistence,
    signInWithCredential,
    signInWithEmailAndPassword,
    signOut,
    updatePassword,
    useDeviceLanguage,
    verifyPasswordResetCode,
} from 'firebase/auth'
// eslint-disable-next-line import/no-cycle
import { removeAuthProvider } from '..'
import AuthInterface from '../interface'

export default class FirebaseAuthProvider extends AuthInterface {
    constructor() {
        super()
        setPersistence(auth, browserSessionPersistence)
        useDeviceLanguage(auth)
    }

    async getIDToken() {
        return auth.currentUser ? auth.currentUser.getIdToken() : null
    }

    setIDToken() {
        // eslint-disable-next-line no-useless-return
        return
    }

    onChangeSubscriber(callback) {
        return onAuthStateChanged(auth, (u) => callback(!!u))
    }

    setTenant(tenant) {
        switch (tenant) {
            case 'consumer':
                auth.tenantId = null
                break
            case 'merchant':
                auth.tenantId = window.configuration['FIREBASE_AUTH_MERCHANT_TENANT_ID']
                break
            default:
                auth.tenantId = null
                break
        }
    }

    setTenantByID(tenantID) {
        auth.tenantId = tenantID
    }

    createRecaptcha() {
        return new RecaptchaVerifier(auth, 'recaptcha', { size: 'invisible' })
    }

    clearRecaptcha(recaptcha) {
        try {
            recaptcha.clear()
        } catch (e) {
            // eslint-disable-next-line no-console
            console.warn(e)
        }
        const el = document?.getElementById('recaptcha-wrapper')
        if (el) el.innerHTML = `<div id='recaptcha' />`
    }

    async sendVerificationEmail(redirect = undefined) {
        if (!auth.currentUser) throw new Error('User is not logged in')
        const actionCodeSettings = {
            url: `https://${window.location.hostname}?email=${auth.currentUser.email}${redirect ? `&redirect=${redirect}` : ''}`,
        }
        return sendEmailVerification(auth.currentUser, actionCodeSettings)
    }

    async sendPasswordResetEmail(email) {
        return sendPasswordResetEmail(auth, email)
    }

    async sendPhoneCode(phone, recaptcha = undefined) {
        if (!recaptcha) return new PhoneAuthProvider(auth).verifyPhoneNumber(phone, this.createRecaptcha())
        return new PhoneAuthProvider(auth).verifyPhoneNumber(phone, recaptcha)
    }

    async loginWithPhoneCode(code, validator) {
        const user = await signInWithCredential(auth, PhoneAuthProvider.credential(validator, String(code)))
        if (user) this.setMonitoringUser({ id: user.user.uid, email: user.user?.email ?? undefined, username: user.user?.displayName ?? undefined })
    }

    async loginWithEmailAndPassword(email, password) {
        const user = await signInWithEmailAndPassword(auth, email, password)
        if (user) this.setMonitoringUser({ id: user.user.uid, email: user.user?.email ?? undefined, username: user.user?.displayName ?? undefined })
    }

    async refreshUser() {
        await auth.currentUser.reload()
    }

    async resetPassword(newPassword) {
        const u = auth.currentUser
        if (!u) throw new Error('User is not logged in')
        return updatePassword(u, newPassword)
    }

    async verifyPasswordResetCode(code) {
        await verifyPasswordResetCode(auth, String(code))
    }

    async confirmPasswordReset(code, password) {
        return confirmPasswordReset(auth, String(code), password)
    }

    async verifyEmailCode(code) {
        return applyActionCode(auth, String(code))
    }

    async getEmailVerified() {
        await auth.currentUser.reload()
        return !!auth.currentUser?.emailVerified
    }

    async logOut() {
        signOut(auth)
        this.setMonitoringUser(null)
        return removeAuthProvider()
    }
}
