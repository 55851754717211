/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { useTime } from 'vspry-hooks'

import { useAuth } from 'context/authContext'
import { formatDateTimeLocale } from 'utils/dates'

import { Text, FlexBox } from 'vspry-style-components'
import { formatNameToMaxLength } from 'utils/strings'

const Container = styled(FlexBox)`
    padding: 0px 10px 0px 0px;
`

export default function UserInfo({ children }) {
    const { user } = useAuth()
    const time = useTime()
    if (!user) return <div />

    return (
        <Container justify='flex-end' align='center' gap='small' width='250px'>
            <div>
                <Text $light $right size='xSmall' $bold margin='no'>
                    {user.person.fullName?.toUpperCase() ?? formatNameToMaxLength(user.person, 25).toUpperCase()}
                </Text>
                <Text $light $right size='tiny' $bold margin='no'>
                    {formatDateTimeLocale(time)}
                </Text>
            </div>
            {children}
        </Container>
    )
}

UserInfo.propTypes = {
    children: PropTypes.node,
}

UserInfo.defaultProps = {
    children: null,
}
