/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import { useFunctionState } from 'vspry-hooks'
import { FlexBox, Icon, Text, Tile } from 'vspry-style-components'

import { useAuth } from 'context/authContext'

import useInterval from 'hooks/useInterval'
import { useRouting } from 'context/routingContext'

const initState = {
    loading: false,
    errorMessage: null,
    redirect: '',
}

export default function VerifyEmail() {
    const location = useLocation()
    const { navigateTo } = useRouting()
    const { user, refreshUser } = useAuth()
    const [state, setState] = useFunctionState(initState)
    const { loading, error, errorMessage } = state

    const checkVerified = async () => {
        if (await window.auth.getEmailVerified()) refreshUser()
    }

    useInterval(async () => checkVerified(), 2000)

    useEffect(() => {
        if (!user) navigateTo('/')
    }, [])

    useEffect(() => window.auth.sendVerificationEmail(encodeURIComponent(`${location.pathname}${location.search}`)), [])

    const sendEmail = async () => {
        setState({ loading: true })

        try {
            await window.auth.sendVerificationEmail(encodeURIComponent(`${location.pathname}${location.search}`))
            setState({ loading: false })
        } catch (e) {
            setState({ loading: false, error: true, errorMessage: e.message })
        }
    }

    return (
        <Tile>
            <FlexBox $column align='center' justify='center' style={{ padding: '20px' }}>
                {error && (
                    <Text size='small' margin='no' color='error'>
                        {errorMessage}
                    </Text>
                )}
                <Icon name='send' size='large' />
                <Text size='xSmall' margin='large'>
                    A verification email has been sent to {user.email}. Open it up to verify your email address (be sure to check your junk mail too!)
                </Text>
                {!loading && (
                    <Text id='send-email-button' $bold size='small' onClick={sendEmail}>
                        I did not receive an email
                    </Text>
                )}
                {loading && (
                    <Loader inline active>
                        Resending verification email...
                    </Loader>
                )}
            </FlexBox>
        </Tile>
    )
}
