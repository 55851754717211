/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'

import { useAuth } from 'context/authContext'
import { useRouting } from 'context/routingContext'
import { useFunctionState } from 'vspry-hooks'
import { ErrorSwal, SuccessSwal } from 'vspry-style-components'
import { VspryForm } from 'vspry-components'

import { useTheme } from 'context/brandContext'
import { linkPhilHealth } from 'api/holdings'
import PersonalDetailsFormPage from './pages/PersonalDetailsFormPage'
import { checkValues, LeavingSwal } from '../util'

const init = {
    firstName: '',
    middleNames: '',
    lastName: '',
    suffix: '',
    birthDate: '',
    email: '',
    phone: '',
    cardNumber: '',
}

export default function AddPhilHealthForm() {
    const { refreshUser } = useAuth()
    const { navigateHome, navigateBack } = useRouting()
    const theme = useTheme()
    const [state, setState] = useFunctionState(init)
    const { firstName, middleNames, lastName, suffix, birthDate, email, phone, cardNumber } = state

    const handleSubmit = async () => {
        const res = await linkPhilHealth(
            { firstName, middleName: middleNames, lastName, suffix, birthDate: new Date(birthDate).toISOString() },
            email,
            phone,
            cardNumber
        )
        if (!res || !res.linkPhilHealth)
            return ErrorSwal.fire({
                title: 'Error!',
                text: 'Seems something went wrong. Did you enter the correct details?',
                iconColor: theme.error,
                confirmButtonColor: theme.brand1,
            })
        SuccessSwal.fire({ title: 'Card added successfully.', iconColor: theme.success })
        refreshUser()
        return navigateHome()
    }

    const onBack = () => {
        if (checkValues(state)) return LeavingSwal(theme, navigateBack)
        return navigateBack()
    }

    return (
        <VspryForm onSubmit={handleSubmit} onBack={onBack}>
            <VspryForm.Page>
                <PersonalDetailsFormPage state={state} setState={setState} />
            </VspryForm.Page>
        </VspryForm>
    )
}
