/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from 'semantic-ui-react'
import { Text, FlexBox } from 'vspry-style-components'

const Banner = styled.div`
    width: 100%;
    padding: 10px 10px;
    font-weight: bold;
    color: #fff;
    background-color: ${(props) => props.theme.error};

    i {
        font-size: 30px;
        height: inherit;
        margin-right: 10px;
    }

    ${(props) =>
        props.onClick &&
        `
        :hover {
            cursor: pointer;
        }
    `}
`

export default function AlertBanner({ children, onClick }) {
    return (
        <Banner onClick={onClick}>
            <FlexBox $align justify='center'>
                <Icon name='warning circle' />
                <Text $bold $left margin='no' size='xSmall' $light>
                    {children}
                </Text>
            </FlexBox>
        </Banner>
    )
}

AlertBanner.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
}

AlertBanner.defaultProps = {
    children: null,
    onClick: () => null,
}
