/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import theme from './medilink'

export default theme
