/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { ConfirmSwal } from 'vspry-style-components'

export const checkValues = (obj) => {
    const keys = Object.keys(obj)
    for (let i = 0; i < keys.length; i++) {
        const element = obj[keys[i]]
        if (Array.isArray(element) && element.length > 0) return true
        if (!Array.isArray(element) && element !== undefined && element !== null && element !== '') return true
    }
    return false
}

export const LeavingSwal = (theme, onBack) =>
    ConfirmSwal.fire({
        title: 'Leaving?',
        text: 'Are you sure you want to go back? Any data entered in this form will be lost.',
        iconColor: theme.text,
        confirmButtonColor: theme.brand1,
    }).then((res) => res.value && onBack())
