/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
export default {
    name: 'maxicare',
    payor: 'PHILAM',
    banner: 'maxicare.svg',
    companyName: 'Maxicare Healthcare Corporation',
    theme: {
        text: '#464646',
        textLight: '#FFFFFF',
        success: '#00a99d',
        error: '#c23e27',
        warn: '#e39f00',
        background: '#F6F6F6',
        brand1: '#0454cb',
        font: `'Lato', sans-serif`,
        fontURL: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
    },
}
