/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { useAuth } from 'context/authContext'
import { useBrand } from 'context/brandContext'

export default function PrivateRoute() {
    const { user } = useAuth()
    const { name, internalName, theme } = useBrand()

    if (user)
        return (
            <>
                <Helmet>
                    <title>{name}</title>
                    <link rel='shortcut icon' href={`images/icons/${internalName}/favicon.ico`} />
                    <link rel='manifest' href={`images/icons/${internalName}/manifest.webmanifest`} />
                    {theme.fontURL && <link href={theme.fontURL} rel='stylesheet' />}
                </Helmet>
                <Outlet />
            </>
        )
    return <Navigate to='/' />
}
