/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'

import PageContainer from 'components/page'
import ReimbursementApplicationForm from 'components/forms/reimbursementApplicationForm/ReimbursementApplicationForm'

export default function ReimbursementPage() {
    return (
        <PageContainer>
            <ReimbursementApplicationForm />
        </PageContainer>
    )
}
