/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

// the val parameter denotes if it is the value of a key in an object
export const stringify = (obj, val = false) => {
    if (typeof obj === 'string') return val ? `"${obj}"` : obj
    if (Array.isArray(obj))
        return obj.reduce((previous, current, index) => `${previous}${stringify(current)}${index === obj.length - 1 ? ']' : ','}`, '[')
    if (typeof obj !== 'object') return JSON.stringify(obj)

    const props = Object.keys(obj)
        .map((k) => `${k}:${stringify(obj[k], true)}`)
        .join(',')
    return `{${props}}`
}

export const formatCardNumber = (cardNumber) => {
    const cleanCardNumber = cardNumber.replace(/\s/gm, '')
    let formattedNumber = ''
    for (let i = 0; i < cleanCardNumber.length; i += 4) {
        if (i + 4 > cleanCardNumber.length) formattedNumber += cleanCardNumber.substring(i)
        if (i + 4 === cleanCardNumber.length) formattedNumber += cleanCardNumber.substring(i, i + 4)
        else formattedNumber += `${cleanCardNumber.substring(i, i + 4)} `
    }
    return formattedNumber
}

export const formatGovCardNumber = (cardNumber) => {
    const array = [cardNumber.slice(0, 2), cardNumber.slice(2, 11), cardNumber.slice(11)]
    return array.join('-')
}

export const hideCardNumber = (cardNumber) => {
    const cleanCardNumber = cardNumber.replace(/\s/gm, '')
    let formattedNumber = ''
    for (let i = 0; i < cleanCardNumber.length - 4; i++) {
        formattedNumber += '*'
    }
    formattedNumber += cleanCardNumber.substring(cleanCardNumber.length - 4)
    return formatCardNumber(formattedNumber)
}

export const formatBytes = (b) => {
    const bytes = Number(b)
    if (Number.isNaN(bytes)) return `${b} B`
    if (bytes / 1024 ** 3 >= 1) return `${Math.round(bytes / 1024 ** 3)} GB`
    if (bytes / 1024 ** 2 >= 1) return `${Math.round(bytes / 1024 ** 2)} MB`
    if (bytes / 1024 >= 1) return `${Math.round(bytes / 1024)} KB`
    return `${bytes} B`
}

export const readSearchParams = (params) => {
    const res = {}
    decodeURI(params)
        .substring(1)
        .split('&')
        .forEach((p) => {
            const [k, v] = p.split('=')
            res[k] = v
        })

    return res
}

const checkName = (name) => (name ? `${name} ` : '')

const abbreviateName = (name) => {
    if (!name) return ''
    const names = name.split(' ')
    let abbrName = ''
    names.forEach((n) => {
        abbrName += `${n.substring(0, 1)} `
    })
    return abbrName
}

export const formatNameToMaxLength = (person, maxLength) => {
    const { firstName, middleName, lastName, suffix } = person
    let name = `${checkName(firstName)}${checkName(middleName)}${checkName(lastName)}${suffix}`
    if (name.length > maxLength) name = `${checkName(firstName)}${abbreviateName(middleName)}${checkName(lastName)}${suffix}`
    if (name.length > maxLength) name = `${abbreviateName(firstName)}${abbreviateName(middleName)}${checkName(lastName)}${suffix}`
    if (name.length > maxLength) name = `${abbreviateName(firstName)}${checkName(lastName)}${suffix}`
    if (name.length > maxLength) name = `${abbreviateName(firstName)}${abbreviateName(lastName)}${suffix}`
    return name
}
