/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useRef } from 'react'
import { Navigate } from 'react-router-dom'

import { useRouting } from 'context/routingContext'
import { useAuth } from 'context/authContext'

import PageContainer from 'components/page'
import GovernmentCard from 'components/cards/GovernmentCard'
import Barcode from 'components/Barcode'
import { Text, Button, FlexBox } from 'vspry-style-components'

export default function PhilHealth() {
    const svgRef = useRef()
    const { navigateBack } = useRouting()
    const { user } = useAuth()

    const { philHealth } = user.holdings

    const downloadBlob = (blob) => {
        const objectUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = objectUrl
        link.download = 'PhilHealth-card-barcode.svg'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        setTimeout(() => URL.revokeObjectURL(objectUrl), 5000)
    }

    const handleDownload = () => {
        const svg = svgRef.current.innerHTML
        const blob = new Blob([svg], { type: 'image/svg+xml' })
        downloadBlob(blob)
    }

    if (!user?.holdings?.philHealth) return <Navigate to='/' />

    return (
        <PageContainer>
            <FlexBox $column gap='small'>
                <GovernmentCard card={philHealth} sheen />
                <Text margin='sTop' size='small'>
                    Your PhilHealth Card Barcode
                </Text>
                <Barcode code={philHealth.cardNumber} svgRef={svgRef} />
                <Button $fitted $light onClick={() => handleDownload()}>
                    Save Barcode
                </Button>
                <Button $fitted $light onClick={() => navigateBack()}>
                    Back
                </Button>
            </FlexBox>
        </PageContainer>
    )
}
