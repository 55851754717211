/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { Counter, FlexBox, Text } from 'vspry-style-components'

const Tile = styled(FlexBox)`
    padding: 5px 10px;
    height: 50px;
    transition: all 200ms linear;
    border-radius: 5px;

    :hover {
        background-color: #0001;
        cursor: pointer;
    }
`

const StyledCounter = styled(Counter)`
    margin-left: 5px;
`

export default function NavHeaderTextItem({ children, onClick, count }) {
    return (
        <Tile align='center' justify='center' onClick={onClick}>
            <Text size='small' margin='no' $light>
                {children}
            </Text>
            {count > 0 && <StyledCounter count={count} />}
        </Tile>
    )
}

NavHeaderTextItem.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    count: PropTypes.number,
}

NavHeaderTextItem.defaultProps = {
    count: null,
}
