/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
/* eslint-disable react/function-component-definition */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFetchOnLoad } from 'vspry-hooks'
import { FlexBox, Loader, Text, Icon, FileList } from 'vspry-style-components'

import { useRouting } from 'context/routingContext'
import { getUserDocuments } from 'api/user'

import { PageTile } from 'components/styled'
import Document from './Document'

const AddDocumentContainer = styled.div`
    margin-bottom: 5px;
    padding: 5px 10px;
    background-color: ${(props) => props.theme.text}11;
    border-radius: 5px;
    transition: all 200ms linear;

    ${(props) =>
        props.onClick &&
        `
        :hover {
            cursor: pointer;
            background-color: ${props.theme.text}22;
        }
    `}
`

const IconTile = styled(FlexBox)`
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: ${(props) => props.theme.brand1};
`

const Loading = () => <Loader height='100%' />
const Empty = () => <Text size='small'>Looks like there is no files to display yet...</Text>
const Selected = ({ document, unSelect }) => <Document {...document} onBack={unSelect} />

export default function DocumentList() {
    const [selected, setSelected] = useState(null)
    const [fetching, documents] = useFetchOnLoad(getUserDocuments)
    const [sortedDocuments, setSortedDocuments] = useState([])
    const { navigateTo } = useRouting()

    const select = (s) => setSelected(s)
    const unselect = () => setSelected(null)
    const handleSelect = (file) => (file.id === selected?.id ? unselect() : select(file))

    useEffect(() => documents && documents.length && setSortedDocuments(documents.sort((a, b) => new Date(b.date) - new Date(a.date))), [documents])

    if (fetching) return <Loading />
    if (documents && documents.length === 0) return <Empty />

    return (
        <PageTile>
            <FlexBox justify='center' $align gap='small' margin='bottom'>
                <Text maxLines={1} margin='no'>
                    Document Vault
                </Text>
            </FlexBox>
            <AddDocumentContainer onClick={() => navigateTo('/uploaddoc')}>
                <FlexBox $align>
                    <FlexBox $align gap='xSmall' justify='flex-start' width='95%'>
                        <IconTile align='center' justify='center'>
                            <Icon name='plus' color='textLight' size='large' fitted />
                        </IconTile>
                        <FlexBox $column width='80%'>
                            <Text $left width='100' $overflowing size='xSmall' margin='tiny'>
                                Upload a document
                            </Text>
                            <Text $left size='tiny' margin='no' color='#0005'>
                                Add a document to your vault to use when claiming
                            </Text>
                        </FlexBox>
                    </FlexBox>
                    <Icon name='chevron right' color='#0007' fitted />
                </FlexBox>
            </AddDocumentContainer>
            <FileList files={sortedDocuments} onClick={(file) => handleSelect(file)} selected={selected} />
        </PageTile>
    )
}

Selected.propTypes = {
    document: PropTypes.object.isRequired,
    unSelect: PropTypes.func.isRequired,
}
