/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

export const classifications = [
    'Admission Record',
    'Charge Slip Breakdown',
    'Claim Reimbursement Form',
    'Clinical Abstract',
    'Customer Information Form (CIF)',
    'Diagnostic Report',
    'Discharge Record',
    "Doctor's Referral (Diagnostic)",
    "Doctor's Referral (Lab Order)",
    'Drivers License',
    'Histopathological Report',
    'HMO Card',
    'Identity Document (non-photographic)',
    'Laboratory Report',
    'Letter of Authorization',
    'Letter of Eligibility',
    'Medical Certificate',
    'National ID',
    'Notarized Affidavit of Loss',
    'Operative Record',
    'Passport',
    'PhilHealth Card',
    'Philhealth Sickness Benefit Claim',
    'Police Report',
    'Postal ID',
    'PRC ID',
    'Prescription (Eyewear)',
    'Prescription (Medicine)',
    'Receipt or Proof of Payment',
    'Screenshot',
    'Statement of Account',
    'Statement of Lost ID Card',
    'Unified Multi-purpose ID (UMID)',
    'Vaccination Certificate',
    'Other',
]

export const getClassificationOptions = () => {
    const options = []
    classifications.forEach((c) =>
        options.push({
            key: c,
            text: c,
            value: c,
        })
    )
    return options
}
