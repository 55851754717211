/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { createContext, useContext } from 'react'
import { PropTypes } from 'prop-types'
import { ThemeContext, ThemeProvider } from 'styled-components'

import GlobalStyle from 'style/globalStyle'
import { getTheme } from 'style/theme'

const getBrand = () => getTheme()

const BrandContext = createContext()
export const useBrand = () => useContext(BrandContext)
export const useTheme = () => useContext(ThemeContext)

export function BrandProvider({ children }) {
    const brand = getBrand()
    return (
        <BrandContext.Provider value={brand}>
            <ThemeProvider theme={brand.theme}>
                <GlobalStyle />
                {children}
            </ThemeProvider>
        </BrandContext.Provider>
    )
}

BrandProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
