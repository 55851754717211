/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
export default {
    name: 'Philhealth',
    banner: 'philhealth.png',
    colour: '#7ab528',
    brand1: '#a5b528',
    brand2: '#28b52c',
}
