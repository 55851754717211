/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useFetchOnLoad, useEventListener } from 'vspry-hooks'
import { Button, FlexBox, Text, Icon, Grid, Loader } from 'vspry-style-components'

import { useRouting } from 'context/routingContext'
import { useAuth } from 'context/authContext'
import { getUserDocuments } from 'api/user'

import { PageTile } from 'components/styled'
import PageContainer from 'components/page'
import cards from 'style/cards'

const StyledIcon = styled(Icon)`
    margin: 0px 0px 10px 0px !important;
`

export default function AddDocumentPage() {
    const [perLine, setPerLine] = useState()
    const containerRef = useRef(null)

    const { navigateTo } = useRouting()
    const { features, user } = useAuth()
    const [fetching, documents] = useFetchOnLoad(getUserDocuments)

    const calcPerLine = () => (containerRef.current ? Math.floor(containerRef.current.offsetWidth / 170) : 4)
    useEffect(() => setPerLine(calcPerLine()), [])
    useEventListener('resize', () => setPerLine(calcPerLine()))

    const availableCards = [
        {
            text: 'PhilHealth Card',
            icon: 'ambulance',
            onclick: () => navigateTo('/addphilhealth'),
            brand: cards.philhealth,
            render: !!features.PhilHealth,
            added: !!user.holdings.philHealth,
        },
        {
            text: 'Vaccination Certificate',
            icon: 'syringe',
            onclick: () => navigateTo('/uploaddoc', { state: { classification: 'Vaccination Certificate' } }),
            render: !!features.UploadDocument,
            added: !fetching && !!documents.find((d) => d.classification === 'Vaccination Certificate'),
        },
        {
            text: 'Drivers License',
            icon: 'car',
            onclick: () => navigateTo('/uploaddoc', { state: { classification: 'Drivers License' } }),
            render: !!features.UploadDocument,
            added: !fetching && !!documents.find((d) => d.classification === 'Drivers License'),
        },
        {
            text: 'Passport',
            icon: 'plane',
            onclick: () => navigateTo('/uploaddoc', { state: { classification: 'Passport' } }),
            render: !!features.UploadDocument,
            added: !fetching && !!documents.find((d) => d.classification === 'Passport'),
        },
        {
            text: 'National Identity Card',
            icon: 'address card',
            onclick: () => navigateTo('/uploaddoc', { state: { classification: 'National ID' } }),
            render: !!features.UploadDocument,
            added: !fetching && !!documents.find((d) => d.classification === 'National ID'),
        },
    ]

    const renderCardButton = (card) => (
        <Button
            disabled={card.added}
            key={card.text + card.icon}
            $fitted
            $light
            brand={card.added ? {} : card.brand}
            onClick={!card.added ? card.onclick : null}
        >
            <FlexBox height='110px' $column justify='center' $align>
                <StyledIcon name={card.added ? 'check' : card.icon} size='huge' />
                {card.text}
            </FlexBox>
        </Button>
    )

    return (
        <PageContainer>
            <PageTile ref={containerRef}>
                <Text margin='xBottom'>What document would you like to add?</Text>
                {fetching ? (
                    <Loader />
                ) : (
                    <Grid columns={perLine} gap='medium'>
                        {availableCards.map((card) => card.render && renderCardButton(card))}
                        {availableCards.length % 2 === 1 &&
                            renderCardButton({ text: 'Other', icon: 'question circle', onclick: () => navigateTo('/uploaddoc') })}
                    </Grid>
                )}
            </PageTile>
        </PageContainer>
    )
}
