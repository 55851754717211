/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import * as Sentry from '@sentry/react'

export default class AuthInterface {
    // eslint-disable-next-line class-methods-use-this
    async setMonitoringUser(data) {
        Sentry.setUser(data)
    }
}
