/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import styled from 'styled-components'
import { PopIcon } from 'vspry-style-components'

// eslint-disable-next-line import/prefer-default-export
export const StyledPopIcon = styled(PopIcon)`
    color: ${(props) => props.theme.textLight};
    z-index: 1001;
    font-size: 30px !important;
    margin: 0px !important;
    display: block !important;
    height: 100% !important;

    :hover {
        cursor: pointer;
    }
`
