/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { onMessage } from 'firebase/messaging'

// import { restQuery } from 'api/interface'
import { errorlessMessaging, sendAnalyticsEvent } from 'api/firebase'
import { initNotifications } from 'api/user'
import { useAuth } from 'context/authContext'
import { useMessaging } from 'context/messageContext'
import { useTheme } from 'context/brandContext'

import { ConfirmSwal, FlexBox } from 'vspry-style-components'
import NotificationTile from './Notification'

const NotificationContainer = styled(FlexBox)`
    position: fixed;
    z-index: 100;
`

export default function NotificationHandler() {
    const [notifications, setNotifications] = useState([])
    const [displayNotifications, setDisplayNotifications] = useState([])
    const { refresh } = useMessaging()
    const theme = useTheme()

    const nRef = useRef(notifications)
    nRef.current = notifications

    const { user } = useAuth()
    useEffect(() => {
        const init = async () => {
            if (!user) return () => {}
            if (Notification.permission === 'default' || Notification.permission === 'denied') {
                let res = await ConfirmSwal.fire({
                    title: 'Enable notifications?',
                    text: 'We use notifications to send you important documents and messages.',
                    iconColor: theme.text,
                    confirmButtonColor: theme.brand1,
                })
                if (res?.value) res = await Notification.requestPermission()
                if (res === 'granted') {
                    initNotifications()
                    window.addEventListener('focus', refresh)
                    return () => window.removeEventListener('focus')
                }
            }
            return () => {}
        }
        init()
    }, [user])

    useEffect(
        async () =>
            (await errorlessMessaging())
                ? onMessage(await errorlessMessaging(), (payload) => {
                      refresh()
                      // restQuery('POST', `/messaging/${payload.data.id}`)
                      sendAnalyticsEvent('message_received', user, { messageID: payload.data.id })
                      setNotifications((n) => [...n, payload])
                  })
                : null,
        [user, refresh]
    )

    useEffect(() => {
        setDisplayNotifications([])
        if (notifications.length === 0) return
        for (let i = 0; i < (notifications.length > 10 ? 10 : notifications.length); i++) {
            setDisplayNotifications((n) => [notifications[i], ...n])
        }
    }, [notifications])

    const onClear = (n) => {
        setNotifications(nRef.current.filter((i) => i.data.id !== n.data.id))
    }

    if (notifications.length > 0)
        return (
            <NotificationContainer direction='column' align='center' width='100%' gap='xSmall' margin='small'>
                {displayNotifications.map((n) => (
                    <NotificationTile key={n.data.id} {...n.notification} onClear={() => onClear(n)} />
                ))}
            </NotificationContainer>
        )
    return null
}
