/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

export const monthsMap = {
    JAN: '01',
    FEB: '02',
    MAR: '03',
    APR: '04',
    MAY: '05',
    JUN: '06',
    JUL: '07',
    AUG: '08',
    SEP: '09',
    OCT: '10',
    NOV: '11',
    DEC: '12',
}

export const formatDateShort = (d) => {
    const date = new Date(d)
    let month = date.getMonth() + 1
    if (month < 10) month = `0${month}`
    const year = String(date.getFullYear()).substring(2, 4)
    return `${month}/${year}`
}

export const formatDateUNI = (d) => {
    const date = new Date(d)
    return date.toISOString().split('T')[0]
}

export const formatDateLocale = (d) => {
    const date = new Date(d)
    return `${Object.keys(monthsMap)[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export const formatDateTimeLocale = (d) => {
    const date = new Date(d)
    const dateString = formatDateLocale(date)
    const hourString = String(date.getHours()).padStart(2, '0')
    const minuteString = String(date.getMinutes()).padStart(2, '0')
    return `${dateString} ${hourString}:${minuteString}`
}
