/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useState } from 'react'
import styled from 'styled-components'

import { useMessaging } from 'context/messageContext'
import { useAuth } from 'context/authContext'

import { FlexBox, Text, Icon } from 'vspry-style-components'
import { useRouting } from 'context/routingContext'
import NavBarItem from './NavBarItem'
import NavHeaderTextItem from './NavHeaderTextItem'
import NavHeaderIconItem from './NavHeaderIconItem'
import { StyledPopIcon } from './styled'

const Bar = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    width: 0px;
    height: 100vh;
    z-index: 1002;
    background-color: #fff;
    transition: width 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55), box-shadow 500ms ease-out;
    box-shadow: none;
    overflow: hidden;

    ${(props) => props.showBar && `width: 250px; box-shadow: 3px 5px 10px 5px #00000040;`}
`

const Cover = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: 0px;
    height: 100vh;
    z-index: 0;
    background-color: #0005;
    opacity: 0;
    transition: opacity 500ms linear, width 0s 500ms;

    ${(props) => props.showBar && `width: 100vw; opacity: 1; transition: opacity 500ms linear, width 0s 0s;`}
`

const Contents = styled.div`
    width: 250px;
    height: 100vh;
    padding: 0px 10px;
`

const StyledDivider = styled.div`
    width: 100%;
    border-bottom: 1px solid ${(props) => props.theme.text}44;
    margin: 10px 0px;
`

const NavList = styled.div``

export default function NavBar() {
    const [showBar, setShowBar] = useState(false)
    const { unread } = useMessaging()
    const { features, signout, clearUser } = useAuth()
    const { navigateTo } = useRouting()

    const hasFeatures = () => {
        const keys = Object.keys(features)
        return keys.reduce((total, current) => total || features[current], false)
    }

    const linkList = [
        { onClick: () => navigateTo('/'), icon: 'home', text: 'Home', render: hasFeatures() },
        { onClick: () => navigateTo('/inbox'), icon: 'inbox', text: 'Inbox ', render: features.Inbox, otherProps: { count: unread } },
        { onClick: () => navigateTo('/files'), icon: 'file alternate', text: 'Document Vault', render: features.UploadDocument },
        { onClick: () => navigateTo('/adddoc'), icon: 'upload', text: 'Add Document', render: features.PhilHealth || features.UploadDocument },
        { onClick: () => navigateTo('/reimbursement'), icon: 'paper plane', text: 'Reimbursement', render: features.Reimbursement },
    ]

    const flip = () => setShowBar(!showBar)
    const close = () => setShowBar(false)

    const renderSideBar = () => (
        <>
            <Cover showBar={showBar} onClick={() => showBar && close()} />
            <Bar showBar={showBar}>
                <Contents>
                    <FlexBox padding='medium' $align height='60px'>
                        <Text $left margin='no' size='large'>
                            Hello!
                        </Text>
                        <Icon size='big' name='window close outline' color='text' fitted onClick={flip} />
                    </FlexBox>
                    <StyledDivider />
                    <FlexBox $column height='calc(100% - 70px)'>
                        <NavList>
                            {linkList.map(
                                (item) =>
                                    item.render && (
                                        <NavBarItem
                                            key={item.text + item.icon}
                                            onClick={item.onClick}
                                            icon={item.icon}
                                            closeBar={() => setShowBar(false)}
                                            {...item.otherProps}
                                        >
                                            {item.text}
                                        </NavBarItem>
                                    )
                            )}
                            <StyledDivider />
                            {features.Demo && (
                                <NavBarItem
                                    onClick={() => clearUser()}
                                    icon='remove circle'
                                    closeBar={() => setShowBar(false)}
                                    $reverse
                                    extraLabel='DEMO ONLY'
                                >
                                    Clear Data
                                </NavBarItem>
                            )}
                            <NavBarItem onClick={() => signout()} icon='sign-out' closeBar={() => setShowBar(false)} $reverse>
                                Sign Out
                            </NavBarItem>
                        </NavList>
                    </FlexBox>
                </Contents>
            </Bar>
            {hasFeatures() ? (
                <StyledPopIcon id='menu-icon' name='bars' onClick={flip} count={unread} />
            ) : (
                <StyledPopIcon id='menu-icon' name='sign-out' onClick={() => signout()} />
            )}
        </>
    )

    const renderHeaderMenuWithText = () => (
        <FlexBox $grow justify='center' $align gap='small'>
            {linkList.map(
                (item) =>
                    item.render && (
                        <NavHeaderTextItem key={item.text + item.icon} onClick={item.onClick} {...item.otherProps}>
                            {item.text}
                        </NavHeaderTextItem>
                    )
            )}
        </FlexBox>
    )

    const renderHeaderMenuWithIcons = () => (
        <FlexBox $grow justify='center' $align gap='small'>
            {linkList.map(
                (item) =>
                    item.render && <NavHeaderIconItem key={item.text + item.icon} onClick={item.onClick} icon={item.icon} {...item.otherProps} />
            )}
        </FlexBox>
    )

    if (window.innerWidth < 700) return renderSideBar()
    if (window.innerWidth < 950) return renderHeaderMenuWithIcons()
    return renderHeaderMenuWithText()
}
