/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { Icon } from 'semantic-ui-react'
import { FlexBox, Text } from 'vspry-style-components'

const StyledButton = styled.button`
    margin-top: 10px;
    position: relative;
    width: 288px;
    height: 180px;
    border: 5px solid ${(props) => props.theme.text}44;
    border-color: ${(props) => props.theme.brand1}66;
    ${(props) => props.brand && props.brand.brand1 && `border-color: ${props.brand.brand1}66;`}
    ${(props) => props.brand && props.brand.brand2 && `border-color: linear-gradient(45deg, ${props.brand.brand1}66, ${props.brand.brand2}66);`}
    border-radius: 5px;
    color: ${(props) => props.theme.text};
    transition: transform 200ms linear;

    :hover {
        transform: scale(110%);
        cursor: pointer;
    }
`

const StyledFlex = styled(FlexBox)`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
`

const StyledIcon = styled(Icon)`
    color: ${(props) => props.brand.brand1}22;
`

export default function AddCardButton({ children, ...rest }) {
    return (
        <StyledButton {...rest}>
            <StyledFlex direction='column' justify='center' align='center' gap='xSmall'>
                <StyledIcon brand={rest.brand} name='plus' size='massive' fitted />
            </StyledFlex>
            <Text margin='no' $bold size='small' color={rest.brand.brand1}>
                {children}
            </Text>
        </StyledButton>
    )
}

AddCardButton.propTypes = {
    children: PropTypes.node.isRequired,
    brand: PropTypes.object,
}

AddCardButton.defaultProps = {
    brand: null,
}
