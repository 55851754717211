/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { createContext, useContext, useEffect, useState } from 'react'
import { useFetchOnLoad } from 'vspry-hooks'
import PropTypes from 'prop-types'

import { getUserMessages, setMessageRead } from 'api/user'

const useMessagingProvider = () => {
    const [unread, setUnread] = useState(0)
    const [canRefresh, setCanRefresh] = useState(true)
    const [refreshQueued, setRefreshQueued] = useState(false)
    const [loading, messages, error, refetch] = useFetchOnLoad(getUserMessages)

    const refresh = () => {
        if (canRefresh) {
            setCanRefresh(false)
            setRefreshQueued(false)
            refetch()
            return setTimeout(() => setCanRefresh(true), 10000)
        }
        return setRefreshQueued(true)
    }

    const setMessageReadAndRefresh = async (id) => {
        await setMessageRead(id)
        return refetch()
    }

    useEffect(() => canRefresh && refreshQueued && refresh(), [canRefresh])

    useEffect(() => messages && setUnread(messages.reduce((total, current) => (!current.read ? total + 1 : total), 0)), [messages])

    return { loading, messages, error, refresh, setMessageReadAndRefresh, unread }
}

const MessagingContext = createContext()
export const useMessaging = () => useContext(MessagingContext)

export function MessagingProvider({ children }) {
    const providerValue = useMessagingProvider()
    return <MessagingContext.Provider value={providerValue}>{children}</MessagingContext.Provider>
}

MessagingProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
