/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { createContext, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFunctionState } from 'vspry-hooks'
import { ErrorSwal } from 'vspry-style-components'

import { clearUserData, getUserDetails } from 'api/user'
import { clearAuthRetries } from 'api/authentication'

import { setAuthProvider } from 'services/auth'
import FirebaseAuthProvider from 'services/auth/firebase/provider'

import { useBrand, useTheme } from './brandContext'

const init = {
    user: null,
    features: {},
    unreadMessages: 0,
    emailVerified: false,
}

const useAuthProvider = () => {
    const brand = useBrand()
    const theme = useTheme()
    const [state, setState] = useFunctionState(init)
    const [{ fetching, error }, setOtherState] = useFunctionState({ fetching: true, error: null })
    const { user, features, unreadMessages, emailVerified } = state

    // get user, reauthenticate if it fails to retrieve the user
    const getUser = async () => {
        setState({ emailVerified: await window.auth.getEmailVerified() })
        const [userInfo, err] = await getUserDetails(brand.payor)
        if (!err) {
            clearAuthRetries()
            const availableFeatures = userInfo.features.reduce((set, f) => ({ ...set, [f]: true }), {})
            setState({ user: userInfo, features: availableFeatures })
            return null
        }

        return err.message
    }

    // sign out function
    const signout = async () => {
        if (window.auth) window.auth.logOut()
        setOtherState({ fetching: false, error: null })
    }

    const signin = async (email, password) => {
        setOtherState({ fetching: true, error: null })
        try {
            if (window.auth) await window.auth.loginWithEmailAndPassword(email, password)
            const err = await getUser()
            if (err) {
                setOtherState({ error: err })
                throw new Error(err)
            }
        } catch (e) {
            await signout()
            setOtherState({ error: e.message })
            throw e
        }
        return setOtherState({ fetching: false })
    }

    const refreshUser = async () => {
        setOtherState({ fetching: true })
        try {
            if (window.auth) await window.auth.refreshUser()
            const err = await getUser()
            if (err) setOtherState({ error: err })
        } catch (e) {
            await signout()
            setOtherState({ error: e.message })
        }
        return setOtherState({ fetching: false })
    }

    const expiredSessionCheck = async () => {
        const expiry = new Date(user.sessionExpiry).getTime()
        if (expiry < Date.now())
            return ErrorSwal.fire({
                title: 'Session Expired!',
                text: 'Your session has expired. Log in again to continue.',
                showConfirmButton: true,
                timer: null,
                iconColor: theme.error,
                confirmButtonColor: theme.brand1,
            }).then(() => signout())
        return setTimeout(() => expiredSessionCheck(), expiry - Date.now())
    }
    useEffect(() => user && user.sessionExpiry && expiredSessionCheck(), [user])

    const clearUser = async () => {
        const res = await clearUserData()
        if (!res)
            return ErrorSwal.fire({
                title: 'Error!',
                text: 'An unexpected error occurred while trying to clear your data, please try again.',
                iconColor: theme.error,
            })
        return signout()
    }

    const clearError = () => setOtherState({ error: null })

    // auth state handler function
    const handleAuthStateUpdate = (u) => {
        if (u && !user) refreshUser()
        else {
            setState({ ...init })
            setOtherState({ fetching: false, error: null })
        }
    }
    useEffect(
        () =>
            window.auth.onChangeSubscriber((u) => {
                if (u) setAuthProvider(new FirebaseAuthProvider())
                handleAuthStateUpdate(u)
            }),
        []
    )

    return {
        user,
        features,
        fetching,
        unreadMessages,
        error,
        clearError,
        signout,
        clearUser,
        emailVerified,
        signin,
        refreshUser,
    }
}

// auth provider
const context = createContext({})
export const useAuth = () => useContext(context)

// Auth Provider
const { Provider } = context

export function AuthProvider({ children }) {
    const auth = useAuthProvider()
    return <Provider value={auth}>{children}</Provider>
}
AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
