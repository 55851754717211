/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
body {
    margin: 0;
    font-family: ${(props) => props.theme.font};
    background: ${(props) => props.theme.background};
}

.fade-enter {
    #content {
        opacity: 0;
        transform: translate(0px, 50px);
        z-index: 1;
    }

    #footer {
        opacity: 0;
        z-index: 1;
    }
}
.fade-enter.fade-enter-active {
    #content {
        opacity: 1;
        transform: translate(0, 0);
        transition: opacity 300ms ease-out, transform 300ms ease;
    }

    #footer {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }
}
.fade-exit {
    #content {
        opacity: 1;
        transform: translate(0, 0);
    }

    #footer {
        opacity: 1;
    }
}
.fade-exit.fade-exit-active {
    #content {
        opacity: 0;
        transform: translate(0px, -50px);
        transition: opacity 200ms ease-out, transform 300ms ease;
    }

    #footer {
        opacity: 0;
        transition: opacity 100ms ease-out;
    }
}

.slide-enter {
    opacity: 0;
    transform: translateX(100px);
}

.slide-enter.slide-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: opacity 300ms ease-out, transform 300ms ease;
}

.slide-exit {
    opacity: 1;
    transform: translateX(0px);
}

.slide-exit.slide-exit-active {
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 100ms ease-out, transform 300ms ease;
}

`

export default GlobalStyle
