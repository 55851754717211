/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import validator from 'validator'
import { VspryForm } from 'vspry-components'
import { useFunctionState } from 'vspry-hooks'
import { ErrorMessage, ErrorSwal } from 'vspry-style-components'

import { signUpUser } from 'api/user'

import { useRouting } from 'context/routingContext'
import { useBrand } from 'context/brandContext'
import { useAuth } from 'context/authContext'

// import PersonalPage from './PersonalPage'
import AccountPage from './AccountPage'

export default function SignUpForm() {
    const { navigateTo } = useRouting()
    const location = useLocation()
    const { theme } = useBrand()
    const { signin } = useAuth()

    const [error, setError] = useState('')
    const [validated, setValidated] = useState(false)
    const [state, setState] = useFunctionState({
        email: '',
        password: '',
        phone: '',
        fullName: location.state?.fullName ?? '',
        birthDate: location.state?.birthDate ?? '',
        cardNumber: location.state?.cardNumber ?? '',
    })
    const { email, password, phone } = state

    const onSubmit = async () => {
        if (!email) return setError('You have not entered an email')
        if (!password) return setError('You have not entered a password')
        if (!phone) return setError('You have not entered a phone number')
        if (!validator.isStrongPassword(password))
            return setError(
                'Your password is not strong enough. Please ensure your password is 8 characters long and includes both an uppercase and lowercase letter, a number and a symbol'
            )
        if (!validated) return setError('Please correct any issues before submitting')
        setError('')

        const res = await signUpUser({
            ...state,
            birthDate: new Date(state.birthDate).toISOString(),
        })

        if (!res?.[0]) return ErrorSwal.fire({ title: 'Error!', text: 'Something went wrong, please try again later.' })

        try {
            await signin(state.email, state.password)
            return navigateTo('/home')
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e.message)
            return ErrorSwal.fire({ title: 'Error!', text: 'Something went wrong, please try again later.' })
        }
    }

    return (
        <>
            {error && <ErrorMessage error={error} />}
            <VspryForm onSubmit={onSubmit} onBack={() => navigateTo('/signin?type=card')} buttonColor={theme.textLight}>
                {/* <VspryForm.Page>
                <PersonalPage state={state} setState={setState} />
            </VspryForm.Page> */}
                <VspryForm.Page>
                    <AccountPage state={state} setState={setState} setValidated={setValidated} />
                </VspryForm.Page>
            </VspryForm>
        </>
    )
}
