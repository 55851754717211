/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import modules from 'data/brands/modules'

const getPayor = () => {
    const { host } = window.location
    return host.split('.')[0]
}

export const getTheme = () => {
    let themeName = `./${window.configuration.THEME}.js`
    if (!window.configuration.THEME) {
        const payor = getPayor()
        themeName = `./${payor}.js`
    }

    let newTheme = modules.find((m) => m.name === themeName)
    if (!newTheme) newTheme = modules.find((m) => m.name === './medilink.js')
    return newTheme.module
}

const { theme } = getTheme()

export default theme
