/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Button, Text, FlexBox, TextInput, Tile, Loader, Icon, ErrorMessage } from 'vspry-style-components'
import { useFormValidation, useFunctionState } from 'vspry-hooks'

// components
import { PublicPageContainer } from 'components/page'
import { useBrand } from 'context/brandContext'
import { useRouting } from 'context/routingContext'

const initState = {
    loading: false,
    oobCode: '',
    mode: '',

    emailVerifyError: null,
    emailVerifySuccess: null,

    newPassword: '',
    confirmNewPassword: '',
    showPassword: false,
    passwordValidated: false,
    passwordCodeValid: null,
    passwordResetSuccessful: null,
    passwordResetError: '',
}

export default function RedirectPage() {
    const { theme } = useBrand()
    const [state, setState] = useFunctionState(initState)
    const [searchParams] = useSearchParams()
    const { navigateTo } = useRouting()

    const { validator, onFieldValidate } = useFormValidation((v) => setState({ passwordValidated: v }))

    const {
        emailVerifyError,
        emailVerifySuccess,
        newPassword,
        confirmNewPassword,
        showPassword,
        passwordValidated,
        passwordCodeValid,
        passwordResetSuccessful,
        loading,
        passwordResetError,
    } = state

    // applies a google oob code for email verification
    const applyActionCode = async (code) => {
        try {
            await window.auth.verifyEmailCode(code)
        } catch (error) {
            // Link is invalid, expired or has already been used
            // eslint-disable-next-line no-console
            console.error(error)
            if (error.message.includes('auth/invalid-action-code'))
                return setState({ emailVerifyError: 'Invalid code, please try again.', emailVerifySuccess: false })
            return setState({ emailVerifyError: error.message, emailVerifySuccess: false })
        }
        return setState({ emailVerifySuccess: true })
    }

    useEffect(() => {
        const mode = searchParams.get('mode')
        const oobCode = searchParams.get('oobCode')
        const continueUrl = searchParams.get('continueUrl')
        const tenantId = searchParams.get('tenantId')

        if (tenantId) window.auth.setTenantByID(tenantId)
        if (mode === 'verifyEmail' && oobCode) applyActionCode(oobCode)
        if (continueUrl) setState({ redirect: continueUrl })
        if (oobCode) setState({ oobCode })
        if (mode && (mode === 'verifyEmail' || mode === 'resetPassword')) setState({ mode })
        else navigateTo('/')
    }, [])

    // redirects
    const redirectSignIn = () => navigateTo(state.redirect ? `/signin?redirect=${encodeURIComponent(state.redirect)}` : '/signin')
    const redirectPasswordResetRequest = () => navigateTo('/signin')

    // requests a password reset
    const resetPassword = async () => {
        if (!newPassword) return setState({ passwordResetError: 'You have not entered a new password' })
        if (!confirmNewPassword) return setState({ passwordResetError: 'You have not confirmed your new password' })
        if (confirmNewPassword !== newPassword) return setState({ passwordResetError: 'The passwords do not match' })
        if (!passwordValidated)
            return setState({
                passwordResetError:
                    'Your password is not strong enough. Please ensure your password is 8 characters long and includes both an uppercase and lowercase letter, a number and a symbol',
            })
        setState({ loading: true, passwordResetError: '' })
        if (!state.oobCode) return null
        try {
            await window.auth.verifyPasswordResetCode(state.oobCode)
            setState({ passwordCodeValid: true })
        } catch (error) {
            return setState({ passwordCodeValid: false, loading: false })
        }

        try {
            await window.auth.confirmPasswordReset(state.oobCode, newPassword)
            return setState({ passwordResetSuccessful: true, loading: false })
        } catch (error) {
            return setState({ passwordResetSuccessful: false, loading: false })
        }
    }

    // renders the password reset result
    const renderResetPasswordResult = () => {
        if (passwordCodeValid === null) return null
        if (passwordCodeValid) {
            if (passwordResetSuccessful === null) {
                return (
                    <Loader>
                        <Text color={theme.textLight} margin='no' size='small'>
                            Resetting password...
                        </Text>
                    </Loader>
                )
            }

            if (passwordResetSuccessful) {
                return (
                    <>
                        <Text size='small' color={theme.textLight}>
                            Your password has been updated successfully.
                        </Text>
                        <Button id='reset-success' $fitted $color={theme.textLight} onClick={() => navigateTo('/signin')}>
                            Sign In
                        </Button>
                    </>
                )
            }
        }
        return (
            <>
                <Text size='small' color={theme.textLight}>
                    The link used is invalid or expired. Please request another password reset link.
                </Text>
                <Button id='reset-failure' $fitted $color={theme.textLight} onClick={redirectPasswordResetRequest}>
                    Retry
                </Button>
            </>
        )
    }

    // renders the password reset form
    const renderResetPasswordForm = () => {
        if (passwordCodeValid !== null) return renderResetPasswordResult()

        return (
            <>
                <Text size='small' color={theme.textLight}>
                    To reset your password, enter a new password and confirm it in the fields below.
                </Text>
                {passwordResetError && <ErrorMessage error={passwordResetError} />}
                <Tile>
                    <TextInput
                        id='password-input'
                        type={showPassword ? 'text' : 'password'}
                        label='New password'
                        value={newPassword}
                        validator={(v) => validator.isStrongPassword(v)}
                        setValidated={onFieldValidate('newPassword')}
                        handleChange={(v) => setState({ newPassword: v })}
                        suffix={<Icon onClick={() => setState({ showPassword: !showPassword })} name={!showPassword ? 'eye' : 'eye slash'} />}
                    />
                    <TextInput
                        id='password-confirm-input'
                        type={showPassword ? 'text' : 'password'}
                        label='Confirm password'
                        value={confirmNewPassword}
                        validator={(v) => v === newPassword && v !== ''}
                        setValidated={onFieldValidate('confirmPassword')}
                        handleChange={(v) => setState({ confirmNewPassword: v })}
                        showValidationWhenEmpty
                        validationTriggers={[newPassword]}
                    />
                </Tile>
                <Button $fitted $color={theme.textLight} id='password-reset-button' onClick={resetPassword} loading={loading}>
                    Update password
                </Button>
            </>
        )
    }

    // renders the verify email form
    const renderVerifyEmail = () => {
        if (emailVerifySuccess === null) {
            return (
                <>
                    <Text size='small'>
                        <Loader active inline inverted />
                    </Text>
                    <Text size='small' color={theme.textLight}>
                        Verifying Email...
                    </Text>
                </>
            )
        }
        if (emailVerifySuccess) {
            return (
                <>
                    <Text size='small' color={theme.textLight} margin='no'>
                        Your email has been successfully verified.
                    </Text>
                    <Text size='small' color={theme.textLight} margin='no'>
                        Please login to proceed, or return to your previous session to continue.
                    </Text>
                    <Button id='redirect-sign-in' $fitted onClick={redirectSignIn} $color={theme.textLight}>
                        Sign In
                    </Button>
                </>
            )
        }
        return (
            <>
                <Text size='small' color={theme.textLight}>
                    Verification failed: {emailVerifyError}
                </Text>
                <Button $fitted onClick={() => navigateTo('/')} $color={theme.textLight}>
                    Home
                </Button>
            </>
        )
    }

    // renders the page
    return (
        <PublicPageContainer>
            <FlexBox $column width='350px' gap='small'>
                {state.mode === 'resetPassword' && renderResetPasswordForm()}
                {state.mode === 'verifyEmail' && renderVerifyEmail()}
                <Button onClick={() => navigateTo('/')}>Cancel</Button>
            </FlexBox>
        </PublicPageContainer>
    )
}
