/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect, useState } from 'react'
import { VspryMessage } from 'vspry-components'
import { Loader } from 'vspry-style-components'
import { useParams } from 'react-router-dom'

import { useMessaging } from 'context/messageContext'
import { useRouting } from 'context/routingContext'

import PageContainer from 'components/page'

function MessagePage() {
    const [message, setMessage] = useState()
    const { loading, messages, setMessageReadAndRefresh } = useMessaging()
    const { messageID } = useParams()
    const { navigateTo } = useRouting()

    useEffect(() => {
        if (!loading) setMessage(messages.find((m) => m.id === messageID))
    }, [loading])

    useEffect(() => {
        if (message && !message.read) setMessageReadAndRefresh(message.id)
    }, [message])

    return <PageContainer $wide>{!message ? <Loader /> : <VspryMessage navigateBack={() => navigateTo('/inbox')} {...message} />}</PageContainer>
}

export default MessagePage
