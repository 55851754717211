/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import axios from 'axios'
import * as Sentry from '@sentry/react'
import { stringify } from '../utils/strings'

export const { API_URL } = window.configuration
axios.defaults.withCredentials = true

const queryFailedErr = { message: 'unable to retrieve data from server' }
const mutationFailedErr = { message: 'unable to post data to server' }

// rest interface
export const restQuery = async (method, url, headers = undefined, data = undefined) => {
    try {
        const res = await axios({
            method,
            url,
            headers,
            data,
        })
        return res ? res.data : null
    } catch (e) {
        if (e?.response?.status === 503 && e.response.headers['retry-after']) {
            return { retry: e.response.headers['retry-after'].split(', ')[0] * 1000 }
        }
        Sentry.captureException(e)
        // eslint-disable-next-line no-console
        console.error(Error(e.message ?? e))
        return null
    }
}

export const timedQuery = async (method, url, headers = undefined, data = undefined, timeout = 30000) => {
    let retry = true
    const timer = setTimeout(() => {
        retry = false
    }, timeout)

    do {
        // eslint-disable-next-line no-await-in-loop
        const res = await restQuery(method, url, headers, data)
        if (!res) {
            clearTimeout(timer)
            return null
        }
        if (!res.retry) {
            clearTimeout(timer)
            return res
        }
        // eslint-disable-next-line no-await-in-loop, no-promise-executor-return
        await new Promise((r) => setTimeout(r, res.retry))
    } while (retry)

    return { errors: ['timed-out'] }
}

// ---- gql interface ----

// formatting gql queries
const formatQuery = (q) => {
    let formatted = q.replace(/\n/g, '')
    while (formatted.includes('  ')) formatted = formatted.replace(/ {2}/g, ' ')
    return formatted
}
//  standard graphql operation
export const graphql = async (query) => {
    // replacing formatting characters for more readable queries in the request
    const data = { query: formatQuery(query) }

    // creating auth token
    const token = window.auth ? await window.auth.getIDToken() : ''

    const res = await timedQuery('POST', `${API_URL}/query`, token && { Authorization: `Bearer ${token}` }, data)
    if (res) return [res.data, res.errors?.[0]]
    return [null, queryFailedErr]
}

// gql form operations
// see: https://github.com/jaydenseric/graphql-multipart-request-spec for more info
export const graphFormMutation = async (query, fields) => {
    const headers = { 'Content-Type': 'multipart/form-data' }
    const form = new FormData()
    const variables = {}
    const map = []

    Object.keys(fields).forEach((k) => {
        if (!Array.isArray(fields[k])) {
            variables[k] = null
            map.push([`variables.${k}`])
            form.append(map.length - 1, fields[k])
        } else {
            variables[k] = []
            fields[k].forEach((v, i) => {
                variables[k].push(null)
                map.push([`variables.${k}.${i}`])
                form.append(map.length - 1, v)
            })
        }
    })

    const formMap = map.reduce((o, field, i) => ({ ...o, [i]: field }), {})
    form.append('operations', JSON.stringify({ query: formatQuery(query), variables }))
    form.append('map', JSON.stringify(formMap))

    const res = await timedQuery('POST', `${API_URL}/query`, headers, form)
    if (res) return [res.data, res.errors?.[0]]
    return [null, mutationFailedErr]
}

// gql templates
const buildTemplate = (strings, args) => strings.reduce((total, s, i) => `${total}${s}${args[i] ? stringify(args[i]) : ''}`, '')

// gql query template helper, usage e.g. graphQuery`user { info { firstName } }`
export const graphQuery = async (strings, ...args) => graphql(`query { ${buildTemplate(strings, args)} }`)

// gql mutation template helper, usage e.g. graphMutation`addDevice(input: ${input}) { devices }`
export const graphMutation = async (strings, ...args) => graphql(`mutation { ${buildTemplate(strings, args)} }`)
