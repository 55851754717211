/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React, { useState } from 'react'
// import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Text, FlexBox, Icon, FileList } from 'vspry-style-components'

export default function ConfirmFormPage({ state }) {
    const { files, classification } = state
    const [selected, setSelected] = useState(null)

    const select = (s) => setSelected(s)
    const unselect = () => setSelected(null)
    const handleSelect = (file) => (file.id === selected?.id ? unselect() : select(file))

    return (
        <>
            <FlexBox justify='center' $align gap='small' margin='bottom'>
                <Text margin='no'>Confirm Details</Text>
                <Icon name='check circle' fitted size='large' color='brand1' />
            </FlexBox>
            <Text size='xSmall'>Please confirm the details below are correct.</Text>
            <FlexBox $column gap='medium'>
                {files.length > 0 && <FileList files={files} onClick={handleSelect} selected={selected} />}
                <div>
                    <Text $left size='small'>
                        Classification
                    </Text>
                    <Text $left size='xSmall'>
                        {classification}
                    </Text>
                </div>
            </FlexBox>
        </>
    )
}

ConfirmFormPage.propTypes = {
    state: PropTypes.object.isRequired,
}
