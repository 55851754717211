/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import { API_URL } from './interface'

export const authenticate = async () => {
    localStorage.setItem('authRetries', 1)
    localStorage.setItem('retryTimeout', new Date(new Date().getTime() + 1000 * 60).getTime())
    window.location.href = `${API_URL}/auth/login?returnurl=${window.location.href.split('?')[0]}`
}

export const unauthenticate = async () => {
    window.location.href = `${API_URL}/auth/logout`
}

export const checkAuthRetries = () => {
    const retries = localStorage.getItem('authRetries')
    const timeout = localStorage.getItem('retryTimeout')
    if (!retries || !timeout) return false

    return timeout > new Date().getTime()
}
export const clearAuthRetries = () => localStorage.removeItem('authRetries') && localStorage.removeItem('retryTimeout')
