/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

// eslint-disable-next-line import/no-cycle
import FirebaseAuthProvider from './firebase/provider'

export const setAuthProvider = (provider) => {
    window.auth = provider
}

export const setDefaultAuthProvider = () => setAuthProvider(new FirebaseAuthProvider())

export const removeAuthProvider = () => {
    setDefaultAuthProvider()
}
