/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import { graphMutation } from './interface'

export const linkVaccineCert = async (person, vaccinations) => {
    const input = { ...person, vaccinations }
    const [res, err] = await graphMutation`
        linkVaxCertPH(input: ${input}) {
            person {
                firstName
                middleName
                lastName
                suffix
                birthDate
            }
            vaccinations {
                location
                date
            }
        }
    `
    if (err) return null
    return res
}

export const linkPhilHealth = async (person, email, phone, cardNumber) => {
    const input = { ...person, email, phone, cardNumber }
    const [res, err] = await graphMutation`
        linkPhilHealth(input: ${input}) {
            cardNumber
            person {
                firstName
                middleName
                lastName
                suffix
                birthDate
            }
        }
    `
    if (err) return null
    return res
}
