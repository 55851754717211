/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

export default {
    internalName: 'pacific-cross',
    name: 'Pacific Cross',
    payor: 'PHILAM',
    banner: 'pacific-cross.svg',
    companyName: 'Pacific Cross',
    theme: {
        text: '#464646',
        textLight: '#FFFFFF',
        success: '#00a99d',
        error: '#C23E27',
        warn: '#e39f00',
        background: '#F6F6F6',
        brand1: '#1E75BA',
        font: `'Lato', sans-serif`,
        fontURL: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
    },
}
