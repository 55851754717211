/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { FlexBox, Text } from 'vspry-style-components'

import { useBrand } from 'context/brandContext'

import { PublicPageContainer } from 'components/page'
import SignUpForm from 'components/signup/SignUpForm'

export default function SignUpPage() {
    const { theme } = useBrand()

    return (
        <PublicPageContainer>
            <FlexBox $column width='350px' gap='small'>
                <Text size='small' color={theme.textLight}>
                    Please provide your details below to set up your new account.
                </Text>
                <SignUpForm />
            </FlexBox>
        </PublicPageContainer>
    )
}
