/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useFunctionState } from 'vspry-hooks'

import CardNumberSignIn from 'components/signin/CardNumberSignIn'
import EmailSignIn from 'components/signin/EmailSignIn'
import { PublicPageContainer } from 'components/page'

export default function SignInPage() {
    const [{ type }, setState] = useFunctionState({ type: '' })
    const [searchParams] = useSearchParams()
    const location = useLocation()

    useEffect(() => {
        const t = searchParams.get('type')
        setState({ type: t })
    }, [location])

    const renderSignIn = () => (type === 'card' ? <CardNumberSignIn /> : <EmailSignIn />)

    return <PublicPageContainer>{renderSignIn()}</PublicPageContainer>
}
