/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useFunctionState } from 'vspry-hooks'
import { ErrorSwal, SuccessSwal } from 'vspry-style-components'
import { VspryForm } from 'vspry-components'

import { useRouting } from 'context/routingContext'
import { useTheme } from 'context/brandContext'

import { uploadUserDocument } from 'api/user'

import CaptureOrUploadFormPage from './pages/CaptureOrUploadFormPage'
import ConfirmFormPage from './pages/ConfirmFormPage'
import { LeavingSwal } from '../util'

const init = {
    files: [],
    classification: '',
    navigatedClass: false,
}

export default function UploadDocForm() {
    const [state, setState] = useFunctionState(init)
    const { navigateHome, navigateBack } = useRouting()
    const theme = useTheme()
    const params = useLocation().state

    useEffect(() => params && params.classification && setState({ classification: params.classification, navigatedClass: true }), [])

    const onSubmit = async () => {
        const [, err] = await uploadUserDocument(state.classification, state.files)
        if (err)
            return ErrorSwal.fire({
                title: 'something went wrong',
                text: 'a problem occurred while uploading your document.',
                iconColor: theme.error,
                confirmButtonColor: theme.brand1,
            })

        SuccessSwal.fire({ title: 'Success!', text: 'You document has been uploaded successfully.', iconColor: theme.success })
        return navigateHome()
    }

    const checkValues = (s) => (s.files && s.files.length > 0) || (!s.navigatedClass && s.classification !== '')

    const onBack = () => {
        if (checkValues(state)) return LeavingSwal(theme, navigateBack)
        return navigateBack()
    }

    return (
        <VspryForm onSubmit={onSubmit} onBack={onBack} transitionName='slide' transitionTimeout={300}>
            <VspryForm.Page>
                <CaptureOrUploadFormPage state={state} setState={setState} />
            </VspryForm.Page>
            <VspryForm.Page>
                <ConfirmFormPage state={state} />
            </VspryForm.Page>
        </VspryForm>
    )
}
