/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { FlexBox, PopIcon } from 'vspry-style-components'

const Tile = styled(FlexBox)`
    padding: 5px 10px;
    height: 50px;
    width: 50px;
    transition: all 200ms linear;
    border-radius: 5px;

    :hover {
        background-color: #0001;
        cursor: pointer;
    }
`

const StyledIcon = styled(PopIcon)`
    display: block !important;
    height: 100% !important;
`

export default function NavHeaderIconItem({ onClick, icon, count }) {
    return (
        <Tile align='center' justify='center' onClick={onClick}>
            <StyledIcon name={icon} count={count} color='textLight' size='30px' fitted />
        </Tile>
    )
}

NavHeaderIconItem.propTypes = {
    onClick: PropTypes.func.isRequired,
    icon: PropTypes.string.isRequired,
    count: PropTypes.number,
}

NavHeaderIconItem.defaultProps = {
    count: null,
}
