/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { getAnalytics, logEvent } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'

const app = initializeApp({
    apiKey: window.configuration.FIREBASE_API_KEY,
    authDomain: window.configuration.FIREBASE_AUTH_DOMAIN,
    projectId: window.configuration.FIREBASE_PROJECT_ID,
    appId: window.configuration.FIREBASE_APP_ID,
    storageBucket: window.configuration.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: window.configuration.FIREBASE_MESSAGING_SENDER_ID,
})

export const errorlessMessaging = async () => {
    const isSupp = await isSupported()
    if (isSupp) return getMessaging(app)
    return null
}

export const messagingKey = window.configuration.VAPID_KEY
export const getMessagingToken = async () => {
    try {
        const messaging = await errorlessMessaging()
        if (!messaging) return null
        const token = await getToken(messaging, { vapidKey: messagingKey })
        return token
    } catch (e) {
        return null
    }
}

const analytics = getAnalytics(app)

export const sendAnalyticsEvent = (event, user, params = {}) => {
    logEvent(analytics, event, { userEmail: user.email, ...params })
}

export const auth = getAuth(app)
