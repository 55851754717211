/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { setDefaultAuthProvider } from 'services/auth'
import App from './App'

Sentry.init({
    dsn: window.configuration.SENTRY_DSN,
    integrations: [new BrowserTracing(), new Sentry.Replay({ networkDetailAllowUrls: [''] })],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    environment: window.configuration.ENVIRONMENT || 'production',
})

setDefaultAuthProvider()
window.auth.setTenant('consumer')

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
)
