/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import { useAuth } from 'context/authContext'

import Error from 'components/ErrorHandler'

// routes
import PrivateRoute from 'components/page/PrivateRoute'
import Home from './home'
import Card from './Card'
import PhilHealth from './PhilHealth'
import AddPhilHealthPage from './AddPhilHealthPage'
import Inbox from './InboxPage'
import UploadDoc from './UploadDoc'
import Files from './Files'
import AddDocumentPage from './AddDocumentPage'
import MessagePage from './MessagePage'
import SignInPage from './SignInPage'
import LandingPage from './LandingPage'
import SignUpPage from './SignUpPage'
import ReimbursementPage from './ReimbursementPage'
import RedirectPage from './RedirectPage'
import VerificationPage from './VerificationPage'
import ResetPasswordPage from './ResetPasswordPage'
import FetchingPage from './FetchingPage'

export default function ViewController() {
    const { features, user, emailVerified, fetching } = useAuth()
    const location = useLocation()

    if (fetching) return <FetchingPage />

    let HomeComponent = Home
    if (user && !features.PhilHealth) HomeComponent = Card

    if (user && !emailVerified)
        return (
            <Routes>
                <Route path='/redirect' element={<RedirectPage />} />
                <Route path='*' element={<VerificationPage />} />
            </Routes>
        )

    return (
        <Routes location={location}>
            {/* Public Pages */}
            <Route path='/' element={<LandingPage />} />
            <Route path='/signin' element={<SignInPage />} />
            <Route path='/signup' element={<SignUpPage />} />
            <Route path='/redirect' element={<RedirectPage />} />
            <Route path='/resetpassword' element={<ResetPasswordPage />} />

            {/* Protected Pages */}
            <Route element={<PrivateRoute />}>
                <Route path='/home' element={<HomeComponent />} />
                <Route path='/card' element={<Card />} />
                {features.Inbox && (
                    <Route path='/inbox'>
                        <Route path=':messageID' element={<MessagePage />} />
                        <Route index element={<Inbox />} />
                    </Route>
                )}
                {features.UploadDocument && (
                    <>
                        <Route path='/uploaddoc' element={<UploadDoc />} />
                        <Route path='/files' element={<Files />} />
                    </>
                )}
                {features.PhilHealth && (
                    <>
                        <Route path='/philhealth' element={<PhilHealth />} />
                        <Route path='/addphilhealth' element={<AddPhilHealthPage />} />
                    </>
                )}
                {(features.PhilHealth || features.UploadDocument) && <Route path='/adddoc' element={<AddDocumentPage />} />}
                {features.Reimbursement && <Route path='/reimbursement' element={<ReimbursementPage />} />}
            </Route>

            <Route
                path='*'
                element={
                    <Error errorTitle='404 Page not found' errorMessage="Looks like you're somewhere you shouldn't be!" buttonMessage='Return Home' />
                }
            />
        </Routes>
    )
}
