/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
export default {
    name: 'VaxCertPH',
    banner: 'vaxcert.png',
    colour: '#fff',
    brand1: '#1b6934',
}
