/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useEventListener } from 'vspry-hooks'
import { FlexBox } from 'vspry-style-components'

import { useBrand } from 'context/brandContext'
import { useAuth } from 'context/authContext'

import LogoImage from 'components/cards/LogoImage'
import AlertBanner from 'components/styled/AlertBanner'
import NavBar from 'components/page/header/navigation/NavBar'

import UserInfo from './UserInfo'
import { StyledPopIcon } from './navigation/styled'

const H = styled.div`
    position: sticky;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 20;
`

const HeaderContainer = styled.div`
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 60px;
    background: ${(props) => props.theme.brand1};

    img {
        margin: 10px;
        max-width: 100px;
    }
`
export default function Header({ alert, alertAction }) {
    const brand = useBrand()
    const { signout } = useAuth()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)

    useEventListener('resize', (e) => setWindowWidth(e.target.innerWidth))

    return (
        <H id='header'>
            <HeaderContainer>
                <FlexBox height='60px' $column justify='center' width='250px'>
                    <LogoImage image={brand.banner} text={brand.name} />
                </FlexBox>
                {windowWidth >= 700 && <NavBar />}
                <UserInfo>{windowWidth < 700 ? <NavBar /> : <StyledPopIcon onClick={() => signout()} name='sign-out' />}</UserInfo>
            </HeaderContainer>
            {alert && <AlertBanner onClick={() => alertAction()}>{alert}</AlertBanner>}
        </H>
    )
}

Header.propTypes = {
    alert: PropTypes.string,
    alertAction: PropTypes.func,
}

Header.defaultProps = {
    alert: '',
    alertAction: () => null,
}
