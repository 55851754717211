/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useRef } from 'react'

import { useRouting } from 'context/routingContext'
import { useAuth } from 'context/authContext'

import PageContainer from 'components/page'
import InsuranceCard from 'components/cards/InsuranceCard'
import QRCode from 'components/QRCode'
import { Text, Button, FlexBox } from 'vspry-style-components'

export default function Card() {
    const svgRef = useRef()
    const { navigateTo, navigateHome } = useRouting()
    const { user, features } = useAuth()

    const downloadBlob = (blob) => {
        const objectUrl = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = objectUrl
        link.download = 'QRcode.svg'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        setTimeout(() => URL.revokeObjectURL(objectUrl), 5000)
    }

    const handleDownload = () => {
        const svg = svgRef.current.innerHTML
        const blob = new Blob([svg], { type: 'image/svg+xml' })
        downloadBlob(blob)
    }

    const { mediLinkPH } = user.holdings

    return (
        <PageContainer>
            <FlexBox $column gap='small'>
                <InsuranceCard card={mediLinkPH} sheen />
                <Text margin='sTop' size='small'>
                    Scan the QR code below at the Kiosk
                </Text>
                <QRCode code={mediLinkPH.cardNumber} svgRef={svgRef} />
                <Button $fitted $light onClick={() => handleDownload()}>
                    Save QR Code
                </Button>
                {features.UploadDocument && (
                    <Button $fitted $light onClick={() => navigateTo('/uploaddoc')}>
                        Scan &amp; Upload Document
                    </Button>
                )}
                {(features.VaxCertPH || features.PhilHealth) && (
                    <Button $fitted $light onClick={() => navigateHome()}>
                        Back
                    </Button>
                )}
            </FlexBox>
        </PageContainer>
    )
}
