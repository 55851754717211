/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'

import PageContainer from 'components/page'
import UploadDocForm from 'components/forms/uploadDocForm/UploadDocForm'

export default function UploadDoc() {
    return (
        <PageContainer>
            <UploadDocForm />
        </PageContainer>
    )
}
