/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React from 'react'
import { PropTypes } from 'prop-types'
import { useFormValidation } from 'vspry-hooks'

import { Text, FlexBox, TextInput, DateInput, Icon, PhoneInput } from 'vspry-style-components'

export default function PersonalDetailsFormPage({ state, setState, setFormValidated }) {
    const { firstName, middleNames, lastName, suffix, birthDate, email, phone, cardNumber } = state
    const { validator, onFieldValidate } = useFormValidation(setFormValidated)

    return (
        <>
            <FlexBox justify='center' $align gap='small' margin='bottom'>
                <Text margin='no'>PhilHealth</Text>
                <Icon name='ambulance' fitted size='large' color='brand1' />
            </FlexBox>
            <FlexBox $column gap='small'>
                <TextInput
                    id='firstname-textinput'
                    label='First Name*'
                    value={firstName}
                    handleChange={(value) => setState({ firstName: value })}
                    validator={(v) => v.match(/^\D+$/g)}
                    setValidated={onFieldValidate('firstName')}
                />
                <TextInput
                    id='middlenames-textinput'
                    label='Middle Name(s)'
                    value={middleNames}
                    handleChange={(value) => setState({ middleNames: value })}
                    validator={(v) => v.match(/^\D+$/g) || validator.isEmpty(v)}
                    setValidated={onFieldValidate('middleNames')}
                />
                <FlexBox gap='small'>
                    <TextInput
                        id='lastname-textinput'
                        noMargin
                        label='Last Name*'
                        value={lastName}
                        handleChange={(value) => setState({ lastName: value })}
                        validator={(v) => v.match(/^\D+$/g)}
                        setValidated={onFieldValidate('lastName')}
                    />
                    <TextInput
                        id='suffix-textinput'
                        noMargin
                        width='40'
                        label='Suffix'
                        value={suffix}
                        validator={(v) => v.match(/^\D+$/g) || validator.isEmpty(v)}
                        handleChange={(value) => setState({ suffix: value })}
                        setValidated={onFieldValidate('suffix')}
                    />
                </FlexBox>
                <DateInput
                    id='date-of-birth-input'
                    label='Date of Birth*'
                    value={birthDate}
                    handleChange={(value) => setState({ birthDate: value })}
                    dateFormat='MMDDYYYY'
                    validator={(v) => validator.isDate(v) && validator.isBefore(v)}
                    setValidated={onFieldValidate('birthDate')}
                />
                <TextInput
                    id='email-textinput'
                    label='E-mail*'
                    value={email}
                    handleChange={(value) => setState({ email: value })}
                    validator={(v) => validator.isEmail(v)}
                    setValidated={onFieldValidate('email')}
                />
                <PhoneInput
                    id='phone-textinput'
                    label='Phone Number*'
                    value={phone}
                    handleChange={(value) => setState({ phone: value })}
                    validator={(v) => validator.isMobilePhone(v)}
                    setValidated={onFieldValidate('phone')}
                />
                <TextInput
                    id='cardnumber-textinput'
                    label='Card Number*'
                    placeholder='123456789098'
                    value={cardNumber}
                    handleChange={(value) => setState({ cardNumber: value })}
                    validator={(v) => validator.isNumeric(v) && validator.isLength(v, { min: 12, max: 12 })}
                    setValidated={onFieldValidate('cardNumber')}
                />
            </FlexBox>
        </>
    )
}

PersonalDetailsFormPage.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    setFormValidated: PropTypes.func,
}

PersonalDetailsFormPage.defaultProps = {
    setFormValidated: () => null,
}
