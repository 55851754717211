/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FlexBox, Icon, PhoneInput, TextInput } from 'vspry-style-components'
import { useFormValidation } from 'vspry-hooks'
import { useLocation } from 'react-router-dom'

export default function AccountPage({ state, setState, setValidated, setFormValidated }) {
    const location = useLocation()
    const { validator, onFieldValidate } = useFormValidation(setValidated)

    useEffect(() => setFormValidated(true), [])

    const { email, password, phone, cardNumber } = state
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    return (
        <FlexBox $column gap='small'>
            <TextInput
                id='signup-email-input'
                label='Email *'
                value={email}
                handleChange={(v) => setState({ email: v })}
                validator={(v) => validator.isEmail(v)}
                setValidated={onFieldValidate('email')}
            />
            <TextInput
                id='signup-password-input'
                label='Password *'
                type={showPassword ? 'text' : 'password'}
                value={password}
                handleChange={(v) => setState({ password: v })}
                suffix={<Icon onClick={() => setShowPassword(!showPassword)} name={!showPassword ? 'eye' : 'eye slash'} />}
                validator={(v) => validator.isStrongPassword(v)}
                setValidated={onFieldValidate('password')}
            />
            <TextInput
                id='signup-password-confirm-input'
                label='Confirm Password *'
                type={showPassword ? 'text' : 'password'}
                value={confirmPassword}
                handleChange={(v) => setConfirmPassword(v)}
                validator={(v) => v === password && v !== ''}
                setValidated={onFieldValidate('passwordConfirm')}
                showValidationWhenEmpty
                validationTriggers={[password]}
            />
            <PhoneInput
                id='signup-phone-input'
                label='Phone *'
                value={phone}
                handleChange={(v) => setState({ phone: v })}
                validator={(v) => validator.isMobilePhone(v)}
                setValidated={onFieldValidate('phone')}
                defaultCountry='ph'
                limit={11}
            />
            <TextInput
                id='signup-cardNumber-input'
                label='Card Number *'
                value={cardNumber}
                handleChange={(v) => setState({ cardNumber: v })}
                disabled={!!location.state?.cardNumber}
                validator={(v) => validator.isNumeric(v)}
                setValidated={onFieldValidate('cardNumber')}
            />
        </FlexBox>
    )
}

AccountPage.propTypes = {
    state: PropTypes.shape({
        email: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
        phone: PropTypes.string.isRequired,
        cardNumber: PropTypes.string.isRequired,
    }).isRequired,
    setState: PropTypes.func.isRequired,
    setValidated: PropTypes.func,
    setFormValidated: PropTypes.func,
}

AccountPage.defaultProps = {
    setValidated: () => null,
    setFormValidated: () => null,
}
