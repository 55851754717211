/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

const req = require.context('./', true, /^\.\/[a-zA-Z0-9-]+\.js$/)
const moduleNames = req.keys().filter((m) => m !== './modules.js')
const modules = moduleNames.map((m) => ({ name: m, module: req(m).default }))

export default modules
