/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
export default {
    name: 'DriversLicense',
    colour: '#fff',
    brand1: '#a2d6f1',
}
