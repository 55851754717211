/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { Text, CaptureImage, FlexBox, FileDropzone, FileTileGallery, Icon, DropDown, ErrorSwal } from 'vspry-style-components'

import { getClassificationOptions } from 'data/classificationOptions'
import { useTheme } from 'context/brandContext'
import { isMobile } from 'react-device-detect'

const StyledFlexBox = styled(FlexBox)`
    background-color: ${(props) => props.theme.text}11;
    border: 3px dashed ${(props) => props.theme.text}11;
    width: 100%;
    border-radius: 5px;

    ${(props) => props.disabled && `filter: grayscale(80%) brightness(150%); `}
`

const InputField = styled.div`
    padding: 20px 10px;
    border-radius: 4px;

    :hover {
        background-color: ${(props) => props.theme.success}22;
        cursor: pointer;
    }
`

const options = getClassificationOptions()

export default function CaptureOrUploadFormPage({ state, setState, setFormValidated, maxFiles }) {
    const { files, classification } = state
    const theme = useTheme()
    const [disabled, setDisabled] = useState(false)

    // validates the form
    useEffect(() => {
        if (setFormValidated) {
            if (!files || !files.length || !classification) return setFormValidated(false)
            return setFormValidated(true)
        }
        return null
    }, [files, classification])

    // manages file upload limits
    useEffect(() => {
        if (files.length < maxFiles) return setDisabled(false)
        return setDisabled(true)
    }, [files])

    const onAdd = (newFiles) => {
        if (!newFiles || !newFiles.length) return null
        if (newFiles.length + files.length > maxFiles)
            return ErrorSwal.fire({
                title: 'Error!',
                text: `You can only upload a maximum of ${maxFiles} file${maxFiles > 1 && 's'}.`,
                iconColor: theme.error,
                confirmButtonColor: theme.brand1,
            })
        return setState({ files: [...files, ...newFiles] })
    }

    const onRemove = (file) => {
        setState({ files: files.filter((f) => f !== file) })
    }

    return (
        <>
            <FlexBox justify='center' $align gap='small' margin='bottom'>
                <Text margin='no'>Upload Documents</Text>
                <Icon name='file alternate' fitted size='large' color='brand1' />
            </FlexBox>
            <FlexBox $column gap='small'>
                <DropDown
                    label='Select a classification for your documents'
                    id='classification-dropdown'
                    value={classification}
                    options={options}
                    placeholder='Select a classification...'
                    handleChange={(value) => setState({ classification: value, navigatedClass: false })}
                />
                <StyledFlexBox align='center' padding='medium' gap='small' disabled={disabled}>
                    {isMobile && (
                        <>
                            <CaptureImage onAdd={onAdd} disabled={disabled}>
                                <InputField>
                                    <Icon name='camera' size='huge' color='brand1' />
                                    <Text size='xSmall'>Take a picture</Text>
                                </InputField>
                            </CaptureImage>
                            <Text size='xSmall' margin='no'>
                                OR
                            </Text>
                        </>
                    )}

                    <FileDropzone onAdd={onAdd} disabled={disabled}>
                        <InputField>
                            <Icon name='upload' size='huge' color='brand1' />
                            <Text size='xSmall'>Drag your files here</Text>
                        </InputField>
                    </FileDropzone>
                </StyledFlexBox>
                {files.length > 0 && <FileTileGallery files={files} onRemove={onRemove} />}
            </FlexBox>
        </>
    )
}

CaptureOrUploadFormPage.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    setFormValidated: PropTypes.func,
    maxFiles: PropTypes.number,
}

CaptureOrUploadFormPage.defaultProps = {
    setFormValidated: () => null,
    maxFiles: 4,
}
