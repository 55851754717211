/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React from 'react'
import styled from 'styled-components'
import { useBrand } from 'context/brandContext'

const F = styled.div`
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;
    padding: 10px;
`

const Version = styled.span`
    font-style: italic;
    font-weight: bold;
    opacity: 0.6;
`

export default function Footer() {
    const brand = useBrand()
    const version = window.configuration.APP_VERSION
    return (
        <F id='footer'>
            © Copyright {new Date().getFullYear()} {brand.companyName}.
            <br />
            All rights reserved.
            <br />
            Powered by Medilink Network, Inc.
            <br />
            Version <Version>{version ? version.substring(0, 20) : '0.0.0.1'}</Version>
        </F>
    )
}
