/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { PropTypes } from 'prop-types'

const useRoutingProvider = () => {
    const location = useLocation()
    const [route, setRoute] = useState({
        current: location.pathname,
        from: location.pathname,
    })
    useEffect(() => setRoute((prev) => ({ current: `${location.pathname}${location.search}`, from: prev.current })), [location])

    const navigate = useNavigate()

    const navigateTo = (to, options = {}) => {
        if (to === `${location.pathname}${location.search}`) return null
        if (window.scrollY !== 0) {
            window.scroll({ top: 0, behavior: 'smooth' })
        }
        return navigate(to, options)
    }

    const navigateHome = () => navigateTo('/')

    const navigateBack = () => {
        if (route.from === location.pathname) return navigateHome()
        return navigateTo(route.from)
    }

    return { navigateTo, navigateBack, navigateHome }
}

const RoutingContext = createContext()
export const useRouting = () => useContext(RoutingContext)

export function RoutingProvider({ children }) {
    const providerValue = useRoutingProvider()
    return <RoutingContext.Provider value={providerValue}>{children}</RoutingContext.Provider>
}

RoutingProvider.propTypes = {
    children: PropTypes.node.isRequired,
}
