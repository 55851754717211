/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import philhealth from './philhealth'
import vaxcert from './vaxcert'
import driverslicense from './driverslicense'

export default { philhealth, vaxcert, driverslicense }
