/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { useFunctionState, useFormValidation } from 'vspry-hooks'
import { Button, DateInput, ErrorMessage, ErrorSwal, FlexBox, TextInput, Tile } from 'vspry-style-components'

import { useBrand } from 'context/brandContext'
import { useRouting } from 'context/routingContext'
import { getUserDetailsByCard } from 'api/user'

const init = {
    cardNumber: '',
    birthDate: '',
    submitting: false,
    validated: false,
    error: '',
}

export default function CardNumberSignIn() {
    const brand = useBrand()
    const { navigateTo } = useRouting()
    const [{ cardNumber, birthDate, submitting, validated, error }, setState] = useFunctionState(init)
    const { validator, onFieldValidate } = useFormValidation((v) => setState({ validated: v }))

    const handleSubmit = async () => {
        if (!cardNumber) return setState({ error: 'You have not entered a card number' })
        if (!birthDate) return setState({ error: 'You have not entered a birth date' })
        if (!validated) return setState({ error: 'Please correct any issues before submitting' })
        setState({ submitting: true })
        const [res, err] = await getUserDetailsByCard(brand.payor, cardNumber, birthDate)
        setState({ submitting: false })
        if (err && err.message.includes('Account already exists')) {
            navigateTo('/signin')
            return ErrorSwal.fire({
                title: 'Account already exists',
                text: 'It appears that you have already registered. Please sign in, or click the “Forgot password” link if you have forgotten your credentials.',
                showConfirmButton: true,
                confirmButtonColor: brand.theme.brand1,
                timer: undefined,
            })
        }
        if (err)
            return ErrorSwal.fire({
                title: 'Invalid Details',
                text: 'Looks like the provided details are either incorrect or we do not have any record of them.',
            })

        return navigateTo('/signup', { state: { cardNumber, birthDate, fullName: res?.person?.fullName } })
    }

    return (
        <FlexBox $column width='350px' gap='small'>
            {error && <ErrorMessage error={error} />}
            <Tile>
                <FlexBox $column $align gap='small'>
                    <TextInput
                        label='Card Number'
                        value={cardNumber}
                        handleChange={(v) => v.length <= 16 && v.match(/^\d*$/) && setState({ cardNumber: v })}
                        validator={(v) => validator.isNumeric(v) && v.length === 16}
                        setValidated={onFieldValidate('cardNumber')}
                    />
                    <DateInput
                        label='Date of Birth'
                        value={birthDate}
                        handleChange={(v) => setState({ birthDate: v })}
                        dateFormat='MMDDYYYY'
                        validator={(v) => validator.isBefore(v)}
                        setValidated={onFieldValidate('birthDate')}
                    />
                </FlexBox>
            </Tile>
            <Button $color='textLight' $fitted onClick={handleSubmit} loading={submitting} disabled={submitting}>
                Validate
            </Button>
            <Button $color='brand1' $fitted onClick={() => navigateTo('/')}>
                Back
            </Button>
        </FlexBox>
    )
}
