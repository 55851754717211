/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import PropTypes from 'prop-types'
import { VspryCard } from 'vspry-components'

import { formatCardNumber, formatNameToMaxLength } from 'utils/strings'
import { useBrand } from 'context/brandContext'
import LogoImage from './LogoImage'

export default function InsuranceCard({ card, onClick }) {
    const brand = useBrand()

    return (
        <VspryCard sheen onClick={onClick} brand={brand}>
            <VspryCard.Header>
                <LogoImage image={brand.banner} text={brand.name} />
            </VspryCard.Header>
            <VspryCard.Mid id='insurance-cardnumber' numberFormat>
                {formatCardNumber(card.cardNumber)}
            </VspryCard.Mid>
            <VspryCard.Lower>
                <VspryCard.Field
                    id='insurance-name'
                    label='name'
                    value={card.person.fullName?.toUpperCase() ?? formatNameToMaxLength(card.person, 25).toUpperCase()}
                    $embossed
                    $light
                    $monospace
                />
            </VspryCard.Lower>
        </VspryCard>
    )
}

InsuranceCard.propTypes = {
    card: PropTypes.object.isRequired,
    onClick: PropTypes.func,
}

InsuranceCard.defaultProps = {
    onClick: null,
}
