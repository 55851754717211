/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const ProfileImage = styled.img`
    display: inline-block;
    height: 60px;
    width: 60px;
    margin-right: 10px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid #2222;
`
