/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { isMobile } from 'react-device-detect'
import { Text, FlexBox, Icon, FileUpload } from 'vspry-style-components'

const maxFiles = 4

export default function UploadFormPage({ state, setState, setFormValidated }) {
    const { chargeSlips, BIRReceipts, providerCertificates, statements } = state

    useEffect(() => setFormValidated(true), [])

    return (
        <>
            <FlexBox justify='center' $align gap='small' margin='bottom'>
                <Text margin='no'>Reimbursement Uploads</Text>
                <Icon name='upload' fitted size='large' color='brand1' />
            </FlexBox>
            <FlexBox $column gap='small'>
                <FlexBox $column>
                    <Text size='xSmall' $left margin='no'>
                        Charge Slip(s)
                    </Text>
                    <FileUpload
                        value={chargeSlips}
                        handleChange={(v) => setState({ chargeSlips: v })}
                        maxFiles={maxFiles}
                        isMobile={isMobile}
                        labels={{
                            takePicture: 'Take a picture',
                            or: 'OR',
                            dragFiles: 'Drag files here',
                        }}
                    />
                </FlexBox>
                <FlexBox $column>
                    <Text size='xSmall' $left margin='no'>
                        BIR Receipts(s)
                    </Text>
                    <FileUpload
                        value={BIRReceipts}
                        handleChange={(v) => setState({ BIRReceipts: v })}
                        maxFiles={maxFiles}
                        isMobile={isMobile}
                        labels={{
                            takePicture: 'Take a picture',
                            or: 'OR',
                            dragFiles: 'Drag files here',
                        }}
                    />
                </FlexBox>
                <FlexBox $column>
                    <Text size='xSmall' $left margin='no'>
                        Provider Certificate(s)
                    </Text>
                    <FileUpload
                        value={providerCertificates}
                        handleChange={(v) => setState({ providerCertificates: v })}
                        maxFiles={maxFiles}
                        isMobile={isMobile}
                        labels={{
                            takePicture: 'Take a picture',
                            or: 'OR',
                            dragFiles: 'Drag files here',
                        }}
                    />
                </FlexBox>
                <FlexBox $column>
                    <Text size='xSmall' $left margin='no'>
                        Statement of Account(s)
                    </Text>
                    <FileUpload
                        value={statements}
                        handleChange={(v) => setState({ statements: v })}
                        maxFiles={maxFiles}
                        isMobile={isMobile}
                        labels={{
                            takePicture: 'Take a picture',
                            or: 'OR',
                            dragFiles: 'Drag files here',
                        }}
                    />
                </FlexBox>
            </FlexBox>
        </>
    )
}

UploadFormPage.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    setFormValidated: PropTypes.func,
}

UploadFormPage.defaultProps = {
    setFormValidated: () => null,
}
