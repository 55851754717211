/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { HashRouter as Router } from 'react-router-dom'

import { BrandProvider } from 'context/brandContext'
import { AuthProvider } from 'context/authContext'
import { RoutingProvider } from 'context/routingContext'
import { MessagingProvider } from 'context/messageContext'

import NotificationHandler from './components/notifications/NotificationHandler'
import ViewController from './pages/ViewController'

export default function App() {
    return (
        <BrandProvider>
            <AuthProvider>
                <MessagingProvider>
                    <NotificationHandler />
                    <Router>
                        <RoutingProvider>
                            <ViewController />
                        </RoutingProvider>
                    </Router>
                </MessagingProvider>
            </AuthProvider>
        </BrandProvider>
    )
}
