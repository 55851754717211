/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Text, Button, FlexBox } from 'vspry-style-components'

import { useRouting } from 'context/routingContext'
import { useAuth } from 'context/authContext'

import PageContainer from './page'

export default function ErrorHandler({ errorTitle, errorMessage, buttonMessage, onClick }) {
    const { navigateHome } = useRouting()
    const { signout } = useAuth()

    return (
        <PageContainer error>
            <FlexBox $column justify='center' $align height='100%' $fitted>
                <Text size='large' margin='no'>
                    {errorTitle}
                </Text>
                <Text size='xSmall' margin='no'>
                    {errorMessage}
                </Text>
                <FlexBox $column $fitted gap='xSmall'>
                    <Button $fitted $light onClick={onClick || (() => navigateHome())}>
                        {buttonMessage}
                    </Button>
                    <Button
                        $fitted
                        $light
                        onClick={() => {
                            signout()
                            navigateHome()
                        }}
                    >
                        Signout
                    </Button>
                </FlexBox>
            </FlexBox>
        </PageContainer>
    )
}

ErrorHandler.propTypes = {
    errorTitle: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    buttonMessage: PropTypes.string.isRequired,
    onClick: PropTypes.func,
}

ErrorHandler.defaultProps = {
    onClick: null,
}
