/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { VspryInbox } from 'vspry-components'

import { useMessaging } from 'context/messageContext'
import { useRouting } from 'context/routingContext'

import PageContainer from 'components/page'

function InboxPage() {
    const { loading, messages } = useMessaging()
    const { navigateTo } = useRouting()

    return (
        <PageContainer $wide>
            <VspryInbox messages={messages} loading={loading}>
                <VspryInbox.Header>
                    <VspryInbox.Title text='Inbox' $left $bold size='medium' margin='small' unread />
                </VspryInbox.Header>
                <VspryInbox.Body onClick={(messageID) => navigateTo(`/inbox/${encodeURIComponent(messageID)}`)} />
            </VspryInbox>
        </PageContainer>
    )
}

export default InboxPage
