/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import PropTypes from 'prop-types'
import { VspryCard } from 'vspry-components'
import { FlexBox, Text } from 'vspry-style-components'

import { formatDateLocale } from 'utils/dates'
import { formatGovCardNumber } from 'utils/strings'

import cards from 'style/cards'

import LogoImage from './LogoImage'
import { ProfileImage } from './styled'

export default function GovernmentCard({ card, onClick }) {
    return (
        <VspryCard sheen onClick={onClick} brand={cards.philhealth}>
            <VspryCard.Header>
                <LogoImage image={cards.philhealth.banner} text={cards.philhealth.name} />
            </VspryCard.Header>
            <VspryCard.Mid id='government-health-cardnumber' numberFormat>
                {formatGovCardNumber(card.cardNumber)}
            </VspryCard.Mid>
            <VspryCard.Lower>
                <FlexBox $align justify='flex-start'>
                    <ProfileImage src='/images/profile-placeholder.png' alt='profile' />
                    <div>
                        <Text id='government-health-name' $left $light size='tiny' margin='tiny' $embossed>
                            {card.person.lastName.toUpperCase()}, {card.person.firstName.toUpperCase()} {card.person.middleName.toUpperCase()}{' '}
                            {card.person.suffix.toUpperCase()}
                        </Text>
                        <Text id='government-health-birthDate-gender' $left $light size='tiny' margin='tiny' $embossed>
                            {formatDateLocale(card.person.birthDate)}, FEMALE
                        </Text>
                        <Text id='government-health-address' $left $light size='tiny' margin='tiny' $embossed>
                            123 Main Street, Makati City
                        </Text>
                    </div>
                </FlexBox>
            </VspryCard.Lower>
        </VspryCard>
    )
}

GovernmentCard.propTypes = {
    card: PropTypes.object.isRequired,
    onClick: PropTypes.func,
}

GovernmentCard.defaultProps = {
    onClick: null,
}
