/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import QR from 'react-qr-code'

const QRDiv = styled.div`
    /* display: inline-block; */
    margin: 0px auto;
    margin-bottom: 20px;
    text-align: center;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 3px 5px 12px 5px #00000040;
`

export default function QRCode({ code, svgRef }) {
    return (
        <QRDiv ref={svgRef}>
            <QR value={code} bgColor='rgba(0, 0, 0, 0)' />
        </QRDiv>
    )
}

QRCode.propTypes = {
    code: PropTypes.string.isRequired,
    svgRef: PropTypes.object,
}

QRCode.defaultProps = {
    svgRef: null,
}
