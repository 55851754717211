/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import { FlexBox, Text } from 'vspry-style-components'

// components
import { PublicPageContainer } from 'components/page'
import VerifyEmail from 'components/verification/VerifyEmail'
import { useBrand } from 'context/brandContext'

export default function VerificationPage() {
    const { theme } = useBrand()

    return (
        <PublicPageContainer>
            <FlexBox $column width='350px' gap='small'>
                <Text size='small' color={theme.textLight}>
                    Before you can start using using your account you must verify your contact information.
                </Text>
                <VerifyEmail />
            </FlexBox>
        </PublicPageContainer>
    )
}
