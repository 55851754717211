/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */

import React from 'react'
import styled from 'styled-components'
import { PropTypes } from 'prop-types'
import { Helmet } from 'react-helmet'
import { useFetchOnLoad } from 'vspry-hooks'
import { FlexBox } from 'vspry-style-components'

import { getUserDocuments } from 'api/user'

import { useBrand } from 'context/brandContext'
import { useRouting } from 'context/routingContext'

import LogoImage from 'components/cards/LogoImage'
import Footer from './Footer'
import Header from './header/Header'

const StyledPublicPageContainer = styled(FlexBox)`
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    background-color: ${(props) => props.theme.brand1};
`

export function PublicPageContainer({ children }) {
    const { name, banner, internalName, theme } = useBrand()
    return (
        <>
            <Helmet>
                <title>{name}</title>
                <link rel='shortcut icon' href={`images/icons/${internalName}/favicon.ico`} />
                <link rel='manifest' href={`images/icons/${internalName}/manifest.webmanifest`} />
                {theme.fontURL && <link href={theme.fontURL} rel='stylesheet' />}
            </Helmet>
            <StyledPublicPageContainer justify='center' width='100%'>
                <FlexBox $column $align justify='center' gap='large' $grow padding='medium'>
                    <LogoImage image={banner} text={name} size='large' />
                    {children}
                </FlexBox>
                <Footer />
            </StyledPublicPageContainer>
        </>
    )
}

PublicPageContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

const Content = styled.div`
    margin: 20px auto 0px auto;
    position: relative;
    z-index: 1;
    max-width: 400px;
    width: 90%;
    min-width: 290px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;
    flex-grow: 1;
    gap: 30px;
    text-align: center;
    ${(props) => props.$wide && `max-width: 90vw;`}
`

const Container = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    min-width: 320px;
`

export default function PageContainer({ children, ...rest }) {
    const [fetching, documents] = useFetchOnLoad(getUserDocuments)

    const { navigateTo } = useRouting()

    let alert = null
    let alertAction = null
    if (!fetching && documents && !documents.find((d) => d.classification === 'Vaccination Certificate')) {
        alert = 'Upload your VaxCert certificate to ensure continued coverage for pandemic-related concerns.'
        alertAction = () => navigateTo('/uploaddoc', { state: { classification: 'Vaccination Certificate' } })
    }

    return (
        <Container>
            <FlexBox $column $fitted $grow>
                <Header alert={alert} alertAction={alertAction} />
                <Content id='content' {...rest}>
                    {children}
                </Content>
            </FlexBox>
            <Footer />
        </Container>
    )
}

PageContainer.propTypes = {
    children: PropTypes.node.isRequired,
    alert: PropTypes.string,
    alertAction: PropTypes.func,
}

PageContainer.defaultProps = {
    alert: '',
    alertAction: () => null,
}
