/* BEGIN_COPYRIGHT_HEADER

Copyright Vspry International Limited (c) 2020
All rights reserved.

END_COPYRIGHT_HEADER */
import React, { useEffect } from 'react'
import { PropTypes } from 'prop-types'

import { Text, FlexBox, TextInput, Icon, DropDown } from 'vspry-style-components'
import { useAuth } from 'context/authContext'

export default function ReimbursementDetailsFormPage({ state, setState, setFormValidated }) {
    const { user } = useAuth()
    const { fullName, cardNumber, provider, claimType } = state

    useEffect(() => setFormValidated(true), [])

    return (
        <>
            <FlexBox justify='center' $align gap='small' margin='bottom'>
                <Text margin='no'>Reimbursement Details</Text>
                <Icon name='clipboard list' fitted size='large' color='brand1' />
            </FlexBox>
            <FlexBox $column gap='small'>
                <TextInput
                    id='fullName-textinput'
                    label='Full Name *'
                    value={fullName}
                    handleChange={(v) => setState({ fullName: v })}
                    disabled={!!user?.person?.fullName}
                />
                <TextInput
                    id='cardnumber-textinput'
                    label='Card Number *'
                    placeholder='123456789098'
                    value={cardNumber}
                    handleChange={(v) => setState({ cardNumber: v })}
                    disabled={!!user?.holdings?.mediLinkPH?.cardNumber}
                />
                <TextInput id='provider-textinput' label='Provider *' value={provider} handleChange={(v) => setState({ provider: v })} />
                <DropDown
                    id='claimType-dropdown'
                    label='Claim Type *'
                    value={claimType}
                    handleChange={(v) => setState({ claimType: v })}
                    options={['Inpatient', 'Outpatient', 'Optical', 'Dental', 'OP Medicine'].map((e) => ({ value: e, text: e, key: e }))}
                />
            </FlexBox>
        </>
    )
}

ReimbursementDetailsFormPage.propTypes = {
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    setFormValidated: PropTypes.func,
}

ReimbursementDetailsFormPage.defaultProps = {
    setFormValidated: () => null,
}
